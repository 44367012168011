;(function () {
	'use strict'

	angular
		.module('resi.login')
		.controller('SignUpPasswordController', ['$stateParams',
      '$state',
      'RegistrationService',
      'MessagesService',
      '$scope', SignUpPasswordController])

	function SignUpPasswordController(
		$stateParams,
		$state,
		RegistrationService,
		MessagesService,
		$scope
	) {
		var vm = this

		vm.restartRegistration = false
		vm.invalidToken = false

		RegistrationService.validate($stateParams.token)
			.then(validationCompleted)
			.catch(registrationFailed)

		function registrationFailed(ex) {
			if (ex.data.key === 'token.expired') {
				MessagesService.error(
					'Der Link ist nicht mehr gültig. Bitte starten Sie die Registrierung noch einmal von vorne.'
				)
				vm.restartRegistration = true
			} else if (ex.data.key === 'token.invalid') {
				vm.invalidToken = true
				MessagesService.error(
					'Der Link ist nicht vollständig. Bitte überprüfen Sie, ob Sie den Link vollständig kopiert haben.'
				)
			} else if (ex.data.key === 'password.invalid') {
				let message
				if (ex.data.message === 'UNSAFE_PASSWORD') {
					message = 'Das Kennwort ist unsicher'
				} else if (ex.data.message === 'EMPTY_PASSWORD') {
					message = 'Das Kennwort ist leer'
				} else if (ex.data.message === 'TRIVIAL_PASSWORD') {
					message = 'Das Kennwort ist zu einfach'
				} else if (ex.data.message === 'REUSED_PASSWORD') {
					message = 'Das Kennwort haben Sie bereits einmal verwendet'
				} else if (ex.data.message === 'PASSWORD_TO_SHORT') {
					message = 'Das Kennwort ist zu kurz'
				}
				MessagesService.error('Das Kennwort ist nicht gültig: ' + message)
			} else {
				MessagesService.error(
					'Registrierung ist fehlgeschlagen. Bitte wenden Sie sich an den Kundensupport.'
				)
			}
		}

		function validationCompleted() {
			vm.invalidToken = false
		}

		vm.registerUserAccount = function (form) {
			$scope.$emit('alert.clear')
			if (form.$invalid) {
				$scope.$broadcast('show-errors-check-validity')
				$scope.$emit('alert.error', 'Die Passwörter stimmen nicht überein.')
				return
			}

			RegistrationService.register(vm.password, $stateParams.token)
				.then(registrationCompleted)
				.catch(registrationFailed)

			function registrationCompleted() {
				$state.go('login.sign-up.success')
			}
		}
	}
})()
