'use strict'

angular.module('resi.settings').factory('SettingsService', ['$http', 'backendEntryPoint', SettingsService])

function SettingsService($http, backendEntryPoint) {
	return {
		changeSeatClass: changeSeatClass,
		changeEmail: changeEmail,
		confirmChangeEmail: confirmChangeEmail,
		changePassword: changePassword,
		changeSubscriptionCode: changeSubscriptionCode
	}

	function changeSubscriptionCode(accountCode, subscriptionCode) {
		return $http.post(backendEntryPoint + '/user/change-subscriptionCode', {
			accountCode: accountCode,
			subscriptionCode: subscriptionCode
		})
	}

	function changeSeatClass(username, seatClass) {
		return $http.post(backendEntryPoint + '/user/change-seatClass', {
			username: username,
			seatClass: seatClass
		})
	}

	function changeEmail(username, newEmailAddress) {
		return $http.post(backendEntryPoint + '/user/change-email', {
			username: username,
			emailAddress: newEmailAddress
		})
	}

	function confirmChangeEmail(token) {
		return $http.post(backendEntryPoint + '/user/confirm-email/' + token, {})
	}

	function changePassword(oldPw, newPw) {
		return $http.post(backendEntryPoint + '/user/change-password', {
			oldPassword: oldPw,
			newPassword: newPw
		})
	}
}
