import angular from 'angular';

var dbMatchDirectiveId = 'dbMatch';

angular.module('resi.directives', ['ui.grid', 'ui.grid.edit'])
	.directive('dbFocus', ['$timeout', DbFocus])
	.directive('dbGridElement', ['$document', 'uiGridEditConstants', DbGridElement])
	.directive('dbAnchorDisabled', AnchorDisabled)
	.directive(dbMatchDirectiveId, ['$parse', '$log', dbMatch]);

// @ngInject
function DbFocus($timeout) {
	return function (scope, elem, attr) {
		$timeout(function () {
			elem[0].focus();
		});
	};
}

// @ngInject
function DbGridElement($document, uiGridEditConstants) {
	return {
		restrict: 'AE',
		link: function ($scope, $elm, $attr) {

			$document.on('click', docClick);

			function docClick(evt) {

				if ($(evt.target).closest('.ui-select-container').size() === 0) {
					$scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
					$document.off('click', docClick);
				}
			}

		}
	};
}

function AnchorDisabled() {
	return {
		restrict: 'A',
		compile: function (tElement, tAttrs) {
			/*jshint -W069 */
			tAttrs.$set('ngClick', '!(' + tAttrs['dbAnchorDisabled'] + ') && (' + tAttrs['ngClick'] + ')');

			return function (scope, element, attr) {
				scope.$watch(attr['dbAnchorDisabled'], function (newValue) {
					if (newValue !== undefined) {
						element.toggleClass('disabled', newValue);
					}
				});

				element.on('click', function (event) {
					if (scope.$eval(attr['dbAnchorDisabled'])) {
						event.preventDefault();
					}
				});

				scope.$on('$destroy', function () {
					element.off('click');
				});
			};
		}
	};
}

// @ngInject
function dbMatch($parse, $log) {
	return {
		restrict: 'A',
		link: link,
		require: 'ngModel'
	};

	function link(scope, element, attrs, ngModel) {
		// führt die Validierung auch aus, wenn andere Validator Fehler melden
		ngModel.$parsers.unshift(validator);
		ngModel.$formatters.push(validator);

		// Änderungen am anderen Model überwachen
		// ! Das andere Model ändert sich nur, wenn es erfolgreich validiert wurde.
		scope.$watch(attrs[dbMatchDirectiveId], ngModelObserver);

		function validator(value) {
			var compareTo = $parse(attrs[dbMatchDirectiveId]);
			var temp = compareTo(scope),
				v = value === temp;

			ngModel.$setValidity('match', v);

			return value;
		}

		function ngModelObserver() {
			validator(ngModel.$viewValue);
		}
	}
}

export default 'resi.directives'
