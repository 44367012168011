export default angular
	.module('resi.booking.account-selection', [])
	.config(['$stateProvider', routeConfig]).name

//@ngInject
function routeConfig($stateProvider) {
	$stateProvider.state('account-selection', {
		url: '/stammplatz-abos',
		data: {
			protected: true
		},
		controller: 'AccountSelectionController',
		controllerAs: 'vm',
		template: require('./account-selection.html').default
	})
}

require('./account-selection.controller')
