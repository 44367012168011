'use strict';
import angular from 'angular';

const resiInterceptors = angular.module('resi.interceptors', ['resi.common']);


var context = require.context(".", true, /^((?!spec).)*\.js$/);
context.keys().forEach(function (key) {
	context(key);
});

export default resiInterceptors.name
