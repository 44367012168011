'use strict'
import angular from 'angular'
import uiRouter from 'angular-ui-router'
import { CoachEditController, CoachListController } from './coach.controller'

import CoachResource from './coach.resource'
import seat from '@/modules/common/stammplatz/directives/seat'
import editor from './db-level-editor'

export default angular
	.module('resi.admin.coachlayout', [uiRouter, seat, editor])
	.config(['$stateProvider', Routes])
	.factory('CoachResource', ['$resource', 'backendEntryPoint', CoachResource]).name

function Routes($stateProvider) {
	// @ngInject
	$stateProvider
		.state('admin.coach-list', {
			url: '/coach/list',
			controller: ['$scope', 'CoachResource', CoachListController],
			template: require('./coach-list.html').default,
			data: {
				pageTitle: 'Wagenkonfiguration'
			}
		})
		.state('admin.coach-edit', {
			url: '/coach/edit/{id}',
			controller: ['$scope',
        'CoachResource',
        '$stateParams',
        '$state',
        'SEAT_SYMBOLS', CoachEditController],
			template: require('./coach-edit.html').default,
			data: {
				pageTitle: 'Wagenkonfiguration'
			}
		})
}
