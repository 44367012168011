;(function () {
	angular
		.module('resi.booking')
		.factory('renewFormStateHandler', formStateHandlerFactory)

	function formStateHandlerFactory() {
		return {
			isInputDisabled: function (ctrlId) {
				return ctrlId === 'fromOrt' || ctrlId === 'toOrt'
			}
		}
	}
})()
