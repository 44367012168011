'use strict';
import angular from 'angular';

angular.module('resi.resources').factory('Seats', ['$http', '$resource', 'backendEntryPoint', function ($http, $resource, backendEntryPoint) {
	return $resource(backendEntryPoint + '/seats', {},
		{
			findStammplatz: {
				method: 'GET',
				url: backendEntryPoint + '/seats/stammplatz',
			}
		});
}])

