'use strict';
import angular from 'angular';

angular.module('resi.resources')
	.factory('Fahrten', ['$resource', 'backendEntryPoint', function ($resource, backendEntryPoint) {
		return $resource(backendEntryPoint + '/timetable', {},
			{
				findStammplatzConnections: {
					url: backendEntryPoint + '/timetable/stammplatz',
					isArray: true
				}
			});
	}])
