export default function CoachResource($resource, backendEntryPoint) {
	return $resource(
		backendEntryPoint + '/admin/coach/:id',
		{},
		{
			importOnce: {
				method: 'POST',
				url: backendEntryPoint + '/admin/coach/import-once'
			},
			copy: {
				method: 'POST',
				url: backendEntryPoint + '/admin/coach/copy/:id'
			},
			page: {
				method: 'GET',
				isArray: true
			},
			totalItems: {
				method: 'GET',
				url: backendEntryPoint + '/admin/coach/count',
				interceptor: {
					response: function (response) {
						if (response.data) {
							response.data = parseInt(response.data)
						}
						// do something else with response.data
						return response
					}
				}
			}
		}
	)
}
