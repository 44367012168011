'use strict';

function SessionData($window) {
	'ngInject';

	this.get = function (key) {
		var rawValue = $window.sessionStorage[key];
		return rawValue ? JSON.parse(rawValue) : null;
	};

	this.put = function (key, value) {
		$window.sessionStorage[key] = JSON.stringify(value);
	};

	this.remove = function (key) {
		delete $window.sessionStorage[key];
	};
}


export default SessionData
