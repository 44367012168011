'use strict'
export default angular.module('resi.pages', []).config(['$stateProvider', Routes]).name

function Routes($stateProvider) {
	$stateProvider
		.state('pages', {
			url: '/pages',
			template: require('./page-base.html').default,
			abstract: true
		})
		.state('pages.faq', {
			url: '/faq',
			template: require('./faq.html').default,
			data: { pageTitle: 'FAQ' }
		})
		.state('pages.privacy', {
			url: '/privacy',
			template: require('./privacy.html').default,
			data: {
				pageTitle: 'Datenschutzhinweise'
			}
		})
		.state('pages.terms-of-service', {
			url: '/tos',
			template: require('./terms-of-service.html').default,
			data: {
				pageTitle: 'Nutzungsbedingungen'
			}
		})
		.state('pages.impressum', {
			url: '/impressum',
			template: require('./impressum.html').default,
			data: {
				pageTitle: 'Impressum'
			}
		})
		.state('pages.contact', {
			url: '/contact',
			template: require('./contact.html').default,
			data: {
				pageTitle: 'Kontakt'
			}
		})
}
