;(function () {
	'use strict'

	angular
		.module('resi.login')
		.controller('ResetPasswordController', ['$stateParams', '$http', 'backendEntryPoint', ResetPasswordController])

	function ResetPasswordController($stateParams, $http, backendEntryPoint) {
		var vm = this
		vm.state = 'IN_PROGRESS'

		$http
			.post(backendEntryPoint + '/user/reset-password/' + $stateParams.token)
			.then(function () {
				vm.state = 'SUCCESS'
			})
			.catch(function (error) {
				vm.state = 'FAILED'
				vm.error = error.message
			})
	}
})()
