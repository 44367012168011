;(function () {
	'use strict'

	angular
		.module('resi.login')
		.controller('PasswordLostController', ['$http',
      'backendEntryPoint',
      'MessagesService',
      '$scope', PasswordLostController])

	function PasswordLostController(
		$http,
		backendEntryPoint,
		MessagesService,
		$scope
	) {
		var vm = this

		vm.showCaptcha = $scope.systemInfo.applicationMode != 'development'

		vm.requestPasswordLostEmail = function (form) {
			if (form.$invalid) {
				$scope.$broadcast('show-errors-check-validity')
				return
			}

			if (vm.showCaptcha && (!vm.captcha || vm.captcha === '')) {
				MessagesService.error('Sie müssen den Roboter Captcha ausfüllen.')
				return
			}

			$http
				.post(backendEntryPoint + '/user/password-lost', {
					username: vm.username,
					captcha: vm.captcha
				})
				.then(function () {
					MessagesService.success(
						'Prüfen Sie Ihren Posteingang um ein neues Passwort anzufordern.'
					)
				})
				.catch(function () {})
		}
	}
})()
