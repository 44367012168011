'use strict';

import resiResource from '@/modules/common/resources'

const seatModule = angular
  .module('resi.seat', [resiResource])
  .directive('seat', ['UserProfile', seat])

// @ngInject
function seat(UserProfile) {

  return {
    restrict: 'E',
    scope: {
      item: '=',
      bookedSeat: '=',
      selectedSeat: '=',
      seatSelectedCallback: '&',
      seatClass: '@'
    },
    template: require('./seat.html').default,
    link: link
  };

  function link($scope, $elm, $attr) {

    function seatEquals(seat1, seat2) {
      return seat1 && seat2 && seat1.seatNr === seat2.seatNr && seat1.coachNr === seat2.coachNr && seat1.trainNr === seat2.trainNr;
    }

    function isInEditMode() {
      return $scope.seatSelectedCallback() === null;
    }

    function isSeatReserved(seat) {
      if (seat.type !== 'SEAT') {
        return false;
      }

      return seat.reserved && (!$scope.bookedSeat || !seatEquals(seat, $scope.bookedSeat));
    }

    function isLongTermReservableSeat(seat) {
      return seat.type === 'SEAT' && seat.attributes.indexOf("StammplatzReservierung") != -1;
    }

    function isOneTimeReservableSeat(seat) {
      return seat.type === 'SEAT' && seat.attributes.indexOf("EinzelplatzReservierung") != -1;
    }

    function seatClassMatches(seat) {
      var seatClassNumber = $scope.seatClass === 'FIRST' ? 1 : 2;
      return seat.type === 'SEAT' && seat.seatClass == seatClassNumber;
    }

    $scope.selectItem = function (item) {
      var callback = $scope.seatSelectedCallback();
      if (callback !== null && item.type === 'SEAT' && isLongTermReservableSeat(item) && seatClassMatches(item) && !isSeatReserved(item)) {
        callback(item);
      }
    };

    $scope.getClass = function (item) {
      var classes = [];

      if (item.type === 'SEAT') {
        var canReserveSeat = isLongTermReservableSeat(item) && seatClassMatches(item) && !isSeatReserved(item);
        if (canReserveSeat) {
          classes.push('reservable');
        } else {
          classes.push('reserved');
        }

        var seatclassname = "seat-";
        if (seatEquals(item, $scope.selectedSeat)) {
          seatclassname += "selected-";
        } else {
          if (canReserveSeat) {
            seatclassname += "reservable-";
          } else {
            seatclassname += "reserved-";
          }
        }
        seatclassname += item.direction.toLowerCase();
        classes.push(seatclassname, 'rotate', 'seat-' + item.direction.toLowerCase());

      } else {
        classes.push(symbols[item.type].css);
        if (symbols[item.type].rotate) {
          classes.push('rotate');
        }
      }

      return classes;
    };

    $scope.getLabelClass = function (item) {
      var classes = [];
      if (item.type === 'SEAT' && isInEditMode()) {

        if (isLongTermReservableSeat(item)) {
          classes.push('longTermReservable');
        }
        if (isOneTimeReservableSeat(item)) {
          classes.push('oneTimeReservable');
        }
      }
      return classes;
    };

    $scope.itemTooltip = function (seat) {

      if (seat.type === 'SEAT') {
        var tooltip = "";
        if ((isLongTermReservableSeat(seat) && seatClassMatches(seat)) ||
          (isInEditMode() && (isLongTermReservableSeat(seat) || isOneTimeReservableSeat(seat)))) {
          if (!isSeatReserved(seat)) {
            tooltip += "Reservierbar";
          } else {
            tooltip += "Bereits reserviert";
          }
        } else {
          tooltip += "Nicht reservierbar";
        }
        tooltip += "\n" + seat.seatClass + ". Klasse";
        return tooltip;
      } else if (seat.type === '=' && seat.views) {
        // Kombination aus mehreren Symbolen
        return seat.views
          .map(
            function (view) {
              if (view.type === 'SEAT') {
                return 'Sitzplatz';
              }
              return symbols[view.type].tooltip;
            }
          ).join('; ');
      }

      return symbols[seat.type].tooltip;
    };
  }
}

let symbols = {
  BIKE: {
    css: 'bike',
    tooltip: 'Fahrradstellplatz',
    symbol: 'B'
  },
  '*': {
    css: 'multi-purpose-area',
    tooltip: 'Mehrzweckabteil',
    symbol: '*',
    noIcon: true
  },
  '=': {
    css: '',
    tooltip: '',
    symbol: '='
  },
  BLOCKED: {
    css: 'blocked',
    tooltip: 'blockiert',
    symbol: 'X'
  },
  EMPTY: {
    symbol: 'E'
  },
  DOOR: {
    css: 'door',
    tooltip: 'Einstiegsbereich',
    symbol: 'D',
    noIcon: true
  },
  FAMILY: {
    css: 'family',
    tooltip: 'Familienabteil',
    symbol: 'F'
  },
  FIRST_CLASS: {
    css: 'first-class',
    tooltip: '1. Klasse',
    symbol: 'FC'
  },
  SECOND_CLASS: {
    css: 'second-class',
    tooltip: '2. Klasse',
    symbol: 'SC'
  },
  LUGGAGE: {
    css: 'luggage',
    tooltip: 'Gepäckablage',
    symbol: 'L'
  },
  TABLE: {
    css: 'table',
    tooltip: 'Tisch',
    rotate: true,
    symbol: 'T'
  },
  WHEELCHAIR: {
    css: 'wheelchair',
    tooltip: 'eingeschränkt',
    symbol: 'R'
  },
  WALL: {
    css: 'wall',
    tooltip: 'Trennwand',
    rotate: true,
    symbol: 'W',
    noIcon: true
  }
}

seatModule.constant('SEAT_SYMBOLS', symbols)

export default seatModule
  .name;
