;(function () {
	'use strict'
	angular
		.module('resi.booking.payment')
		.controller('SimulateController', ['$state', 'bookingNumber', SimulateController])

	function SimulateController($state, bookingNumber) {
		var vm = this

		vm.pay = function () {
			$state.go('payment.success', {
				paymentId: 1234,
				bookingNumber: bookingNumber
			})
		}

		vm.abort = function () {
			$state.go('payment.cancel', { bookingNumber: bookingNumber })
		}
	}
})()
