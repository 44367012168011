(function () {
	'use strict';

	angular.module('resi.resources')
		.factory('AccountResource', ['$resource', 'backendEntryPoint', AccountResource]);

	function AccountResource($resource, backendEntryPoint) {
		return $resource(backendEntryPoint + '/accounts/:code',{});
	}

}());
