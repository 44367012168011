;(function () {
  angular
    .module('resi.booking')
    .factory('seatSelectionDialog', ['$uibModal', '$q', '$document', seatSelectionDialogFactory])

  // @ngInject
  function seatSelectionDialogFactory($uibModal, $q, $document) {
    var instance = null
    var htmlTag = $document.find('html').eq(0)

    return {
      open: openDialog,
      close: closeDialog
    }

    function openDialog(
      from,
      to,
      booking,
      account,
      skipCurrentTimetablePeriod,
      selectedSeat,
      trainNumber,
      days
    ) {
      closeDialog()
      instance = $uibModal.open({
        template: require('./seat-selection-dialog.html').default,
        controller: 'MockSeatSelectionController',
        size: 'lg',
        windowClass: 'fullscreen',
        resolve: {
          seatSelectionData: ['Seats', 'UserProfile',
            function (Seats, UserProfile) {
              var request = {
                account: account,
                skipCurrentTimetablePeriod: skipCurrentTimetablePeriod,
                preventCaching: new Date().getTime(),
                trainNr: trainNumber,
                validSince: booking.validSince,
                validUntil: booking.validUntil,
                from: from.evaNumber,
                to: to.evaNumber,
                days: days
              }

              return $q(function (resolve, reject) {
                UserProfile.getSeatClass().then(function (seatClass) {
                  Seats.findStammplatz(request,
                    function (trainView) {
                      resolve({
                        selectedSeat: selectedSeat,
                        days: days,
                        coaches: trainView.coaches,
                        seatClass: seatClass,
                        trainNr: trainView.trainNr
                      })
                    },
                    function (error) {
                      reject('resolve stammplatz failed' + error)
                    }
                  )
                })
              })
            }
          ]
        }
      })

      // Damit der Footer korrekt dargestellt wird, muss die CSS Klasse 'modal-open' an das HTML-Tag angehängt werden.
      // ui-bootstrap unterstützt aber nur BODY.
      $q.when(instance.opened).then(addClassToHtml)
      $q.when(instance.result).finally(removeClassFromHtml)

      return instance.result
    }

    function closeDialog() {
      if (instance) {
        instance.dismiss('$destroy')
        instance = null
      }
      removeClassFromHtml()
    }

    function addClassToHtml() {
      htmlTag.addClass('modal-open')
    }

    function removeClassFromHtml() {
      htmlTag.removeClass('modal-open')
    }
  }
})()
