;(function () {
	'use strict'

	angular
		.module('resi.login')
		.factory('RegistrationService', ['$http', '$q', 'backendEntryPoint', '$log', RegistrationService])

	function RegistrationService($http, $q, backendEntryPoint, $log) {
		return {
			signUp: signUp,
			register: register,
			validate: validate
		}

		function signUp(email, subscriptionCode, seatClass, captcha) {
			return $q(signUpOperation)

			function signUpOperation(resolve, reject) {
				if (!email || !subscriptionCode) {
					reject()
					return
				}

				return $http
					.post(backendEntryPoint + '/user/sign-up', {
						emailAddress: email,
						subscriptionCode: subscriptionCode,
						seatClass: seatClass,
						captcha: captcha
					})
					.then(signUpCompleted)
					.catch(signUpFailed)

				function signUpCompleted(success) {
					$log.info('SignUp request completed.')
					resolve(success)
				}

				function signUpFailed(error) {
					$log.info('SignUp request FAILED.', error)
					reject(error)
				}
			}
		}

		function validate(token) {
			return $q(validationOperation)

			function validationOperation(resolve, reject) {
				if (!token) {
					reject()
					return
				}

				return $http
					.post(backendEntryPoint + '/user/validate', { requestToken: token })
					.then(validationCompleted)
					.catch(validationFailed)

				function validationCompleted(success) {
					$log.info('Validation completed.')
					resolve(success)
				}

				function validationFailed(error) {
					$log.info('Validation FAILED.', error)
					reject(error)
				}
			}
		}

		function register(password, token) {
			return $q(registrationOperation)

			function registrationOperation(resolve, reject) {
				if (!password || !token) {
					reject()
					return
				}

				return $http
					.post(backendEntryPoint + '/user/register', {
						requestToken: token,
						password: password
					})
					.then(registrationCompleted)
					.catch(registrationFailed)

				function registrationCompleted(success) {
					$log.info('Registration completed.')
					resolve(success)
				}

				function registrationFailed(error) {
					$log.info('Registration FAILED.', error)
					reject(error)
				}
			}
		}
	}
})()
