
'use strict';

function DelayedWatch($timeout) {
	'ngInject';

	return {
		watch: watch
	};

	function watch(scope, prop, callback) {
		var timeoutPromise;
		var delayMs = 300;
		scope.$watch(prop, function (n, o) {
			$timeout.cancel(timeoutPromise);  //does nothing, if timeout alrdy done
			timeoutPromise = $timeout(function () {   //Set timeout
				callback(n, o);
			}, delayMs);
		});
	}

}

export default DelayedWatch
