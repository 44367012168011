function CoachEditController(
	$scope,
	CoachResource,
	$stateParams,
	$state,
	SEAT_SYMBOLS
) {
	var createMode = $stateParams.id === ''

	$scope.refreshLevelTemplate = refreshLevelTemplate

	$scope.addLevel = function (coach) {
		coach.levels.push({
			level: '',
			layout: '',
			seatDefinition: '',
			rows: []
		})
	}

	if (createMode) {
		$scope.coach = new CoachResource({
			levels: [],
			coachType: '0000'
		})
		$scope.addLevel($scope.coach)
	} else {
		$scope.coach = CoachResource.get({
			id: $stateParams.id
		})
		$scope.coach.$promise.then(function () {
			$scope.coach.otherTenant =
				$scope.currentUser().tenant !== $scope.coach.tenant
		})
	}

	$scope.removeLevel = function (coach, lvl) {
		var idx

		// Index in Levels-Array muss "gesucht" werden, da die Reihenfolge im GUI nicht der Reihenfolge im Array 'coach.level' entsprechen muss ==> orderBy
		angular.forEach(coach.levels, function (value, key) {
			if (value.level === lvl) {
				idx = key
			}
		})
		if (angular.isDefined(idx)) {
			coach.levels.splice(idx, 1)
		}
	}

	$scope.submit = function () {
		$scope.coach.levels.forEach(function (level) {
			refreshLevelTemplate(level)
		})
		$scope.$emit('alert.clear')
		$scope.$broadcast('show-errors-check-validity')
		if ($scope.form.$invalid) {
			$scope.$emit('alert.error', 'Pflichtfelder müssen ausgefüllt werden')
			return
		}

		$scope.coach.$save().then(function () {
			$scope.$emit('alert.info', 'Template wurde gespeichert')
			if (createMode) {
				$state.go('admin.coach-edit', {
					id: $scope.coach.id
				})
			}
		})
	}

	function refreshLevelTemplate(level) {
		var layout = '',
			seatDefinition = ''
		level.rows.forEach(function (row) {
			var layoutRow = []
			row.forEach(function (col) {
				if (col.type === 'SEAT') {
					layoutRow.push(col.seatNr)
					seatDefinition +=
						[
							col.seatNr,
							col.attributes.join('/'),
							col.seatClass,
							col.direction
						].join(';') + '\n'
				} else if (col.type === '=') {
					layoutRow.push(
						'= ' +
							col.views
								.map(function (x) {
									return SEAT_SYMBOLS[x.type].symbol
								})
								.join(', ')
					)
				} else {
					layoutRow.push(SEAT_SYMBOLS[col.type].symbol)
				}
			})
			layout += layoutRow.join(' ; ') + '\n'
		})
		level.layout = layout
		level.seatDefinition = seatDefinition
	}
}

function CoachListController($scope, CoachResource) {
	$scope.showOtherTenants = false

	$scope.import = function () {
		$scope.$emit('alert.clear')
		CoachResource.importOnce().$promise.then(function () {
			getPage($scope.showOtherTenants)
		})
	}

	$scope.copy = function (item) {
		$scope.$emit('alert.clear')
		$scope.$emit('alert.info', 'Vorlage wurde kopiert')

		CoachResource.copy({
			id: item.id
		}).$promise.then(function () {
			getPage($scope.showOtherTenants)
		})
	}

	$scope.delete = function (item) {
		$scope.$emit('alert.clear')
		CoachResource.delete({
			id: item.id
		}).$promise.then(function () {
			$scope.$emit('alert.info', 'Eintrag wurde gelöscht')
			getPage($scope.showOtherTenants)
		})
	}

	function getPage(showOtherTenants) {
		$scope.coachTemplates = CoachResource.page({
			page: 0,
			pageSize: 250,
			showOtherTenants: showOtherTenants
		})
	}

	$scope.$watch('showOtherTenants', function () {
		getPage($scope.showOtherTenants)
	})
}

export { CoachListController, CoachEditController }
