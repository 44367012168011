;(function () {
	'use strict'
	angular
		.module('resi.booking.payment')
		.controller('PaymentStartController', ['booking',
      'Reservation',
      '$stateParams',
      'MessagesService',
      '$state',
      'PaymentService',
      '$window',
      'accessService', PaymentStartController])
		.controller('PaymentCancelController', ['booking', 'Reservation', '$state', PaymentCancelController])
		.controller('PaymentCommitController', ['booking',
      'paymentId',
      '$state',
      '$window',
      'PaymentService',
      'MessagesService',
      'accessService', PaymentCommitController])

	function PaymentStartController(
		booking,
		Reservation,
		$stateParams,
		MessagesService,
		$state,
		PaymentService,
		$window,
		accessService
	) {
		var vm = this

		booking.$promise.then(function (bookingView) {
			if (!bookingView.booking.number) {
				MessagesService.error(
					'Buchung ' +
						$stateParams.bookingNumber +
						' konnte nicht gefunden werden'
				)
			} else if (bookingView.booking.status === 'BOOKED') {
				MessagesService.info('Buchung wurde bereits bezahlt.')
			} else {
				bookingView.booking.status = 'BOOKED'
				vm.myBooking = bookingView
			}
		})

		vm.submit = function (form) {
			MessagesService.clear()
			if (!form.$valid) {
				MessagesService.error(
					'Sie müssen die Geschäftsbedingungen und die Datenschutzlinie akzeptieren.'
				)
				return false
			}

			if (vm.useCoupon && (!vm.coupon || vm.coupon.length === 0)) {
				MessagesService.error('Geben Sie bitte die Gutscheinnummer ein.')
				return false
			}

			if (vm.useCoupon) {
				PaymentService.payWithCoupon(
					vm.myBooking.booking.accountCode,
					vm.myBooking.booking.number,
					vm.coupon
				)
					.then(function () {
						MessagesService.info('Bezahlung erfolgreich verbucht')
						if (accessService.hasRole('ROLE_USER')) {
							$state.go(
								'regular-seat',
								{
									nextTimetableHint:
										vm.myBooking.booking.rollout === 'SHORTENED'
								},
								{ reload: true }
							)
						} else {
							$state.go('account-selection', {}, { reload: true })
						}
					})
					.catch(function () {
						MessagesService.clear()
						MessagesService.error('Der Gutschein scheint nicht gültig zu sein.')
					})
			} else {
				PaymentService.checkoutPayment(vm.myBooking.booking.number).then(
					function (logpayRedirectUrl) {
						$window.location.href = logpayRedirectUrl
					},
					function () {
						// redirect to error page
					}
				)
			}
		}

		vm.cancel = function () {
			Reservation.delete({
				bookingNumber: vm.myBooking.booking.number
			}).$promise.then(function () {
				$state.go(
					'regular-seat',
					{ account: booking.booking.accountCode },
					{ reload: true }
				)
			})
		}

		vm.goBack = function () {
			$state.go(
				'regular-seat',
				{ account: booking.booking.accountCode },
				{ reload: true }
			)
		}
	}

	function PaymentCancelController(booking, Reservation, $state) {
		booking.$promise.then(function (aBooking) {
			if (aBooking.booking.status === 'BOOKED_TEMPORARILY') {
				Reservation.delete({
					bookingNumber: aBooking.booking.number
				}).$promise.then(function () {
					$state.go('account-selection', {}, { reload: true })
				})
			} else {
				$state.go('account-selection', {}, { reload: true })
			}
		})
	}

	function PaymentCommitController(
		booking,
		paymentId,
		$state,
    $window,
    PaymentService,
		MessagesService,
		accessService
	) {

    if (!paymentId) {
      paymentId = $window.location.search.substring(7)
    }

		booking.$promise.then(function (aBooking) {
			PaymentService.commitPayment(
				aBooking.booking.accountCode,
				aBooking.booking.number,
				paymentId
			).then(
        function () {
					MessagesService.info('Bezahlung erfolgreich verbucht')
					if (accessService.hasRole('ROLE_USER')) {
						$state.go(
							'regular-seat',
							{ nextTimetableHint: booking.booking.rollout === 'SHORTENED' },
							{ reload: true }
						)
					} else {
						$state.go('account-selection', {}, { reload: true })
					}
				},
				function () {
					MessagesService.clear()
					MessagesService.error(
						'Bei der Bezahlung ist ein Fehler passiert. Wenden Sie sich bitte an den Support. Den Kontakt finden Sie in der Fußzeile.'
					)
				}
			)
		})
	}
})()
