'use strict';
import angular from 'angular';
import ngResource from 'angular-resource'
import resiConfiguration from '@/modules/configuration'
const resiResources = angular.module('resi.resources', [resiConfiguration, ngResource])

var context = require.context(".", true, /^((?!spec).)*\.js$/);
context.keys().forEach(function (key) {
  context(key);
});

export default resiResources.name
