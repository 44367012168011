angular
	.module('resi.security')
	.run(['$rootScope', 'tokenStorage', '$state', RedirectToLoginPage])
	.config(['$httpProvider', function ($httpProvider) {
		$httpProvider.interceptors.push('authenticationInterceptor')
	}])

function RedirectToLoginPage($rootScope, tokenStorage, $state) {
	// @ngInject

	function isLoggedIn() {
		return currentUser() !== null
	}

	function currentUser() {
		var token = tokenStorage.retrieveUser()
		return token
	}

	$rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
		if (!(toState.data && toState.data.protected)) return
		if (isLoggedIn()) return

		console.log('Access denied to', toState.name)

		$rootScope.returnToState = {
			name: toState.name,
			params: toParams
		}

		event.preventDefault()

		$state.go('login.access-denied')
	})
}
