'use strict'
import angular from 'angular'
import ngResource from 'angular-resource'
import uiRouter from 'angular-ui-router'
import uiBootstrap from 'angular-ui-bootstrap'
import datePicker from 'bootstrap-ui-datetime-picker'

import filter from '@/modules/filters'
import 'angular-ui-grid/ui-grid.min'
import 'angular-ui-select/select'
import checklistModel from 'checklist-model'
import ngFileUpload from 'ng-file-upload'

import coachlayout from './coachlayout'
import 'angular-ui-tinymce'

export default angular.module('resi.admin', [
	uiBootstrap,
	datePicker,
	'ui.grid',
	'ui.grid.pagination',
	'ui.grid.i18n',
	'ui.grid.resizeColumns',
	'ui.grid.selection',
	'ui.tinymce',
	'ui.router',
	'ngResource',
	'resi.configuration',
	'resi.directives',
	'ui.grid.edit',
	'ui.grid.rowEdit',
	'ui.grid.cellNav',
	'ui.select',
	'angular-confirm',
	ngFileUpload,
	'resi.configuration',
	ngResource,
	uiRouter,
	'ui.select',
	filter,
	checklistModel,
	coachlayout
]).name

require('./admin.controller')
require('./admin')
require('./directives/year-filter')
