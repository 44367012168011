;(function () {
	'use strict'

	angular
		.module('resi.login')
		.controller('SignUpRequestController', ['RegistrationService',
      'MessagesService',
      '$state',
      '$scope', SignUpRequestController])

	function SignUpRequestController(
		RegistrationService,
		MessagesService,
		$state,
		$scope
	) {
		var vm = this

		vm.showCaptcha = $scope.systemInfo.applicationMode != 'development'

		vm.seatClass = ''

		vm.seatClasses = ['FIRST', 'SECOND']

		vm.datePickerOpened = false
		vm.openDatePicker = openDatePicker
		vm.signUpForRegistration = signUpForRegistration

		function openDatePicker($event) {
			$event.preventDefault()
			$event.stopPropagation()
			vm.datePickerOpened = true
		}

		function signUpForRegistration(form) {
			MessagesService.clear()

			var formIsValid = true

			if (form.$invalid) {
				formIsValid = false
			}

			if (vm.showCaptcha && (!vm.captcha || vm.captcha === '')) {
				MessagesService.error('Sie müssen den Roboter Captcha ausfüllen.')
				formIsValid = false
			}

			if (!formIsValid) {
				$scope.$broadcast('show-errors-check-validity')
				return
			}

			RegistrationService.signUp(
				vm.email,
				vm.subscriptionCode,
				vm.seatClass,
				vm.captcha
			)
				.then(proceed)
				.catch(signUpFailed)

			function proceed() {
				$state.go('login.sign-up.completed')
			}

			function signUpFailed(err) {
				if (err && err.status == 409) {
					MessagesService.error('Die Emailadresse wird bereits verwendet.')
				}
			}
		}
	}
})()
