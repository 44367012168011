'use strict'

import dbTicket from './db-ticket'
import accountSelection from './account-selection'
import payment from './payment'
import resiCommon from '@/modules/common/common.module'
import translate from 'angular-translate'
import uiBootstrap from 'angular-ui-bootstrap'
import seat from '@/modules/common/stammplatz/directives/seat'

export default angular.module('resi.booking', [
	'ui.router',
	resiCommon,
	'resi.configuration',
	'resi.security',
	'resi.resources',
	translate,
	accountSelection,
	dbTicket,
	payment,
	uiBootstrap,
  seat
]).name

require('./directives')
require('./services')
require('./regular-seat')
