'use strict';
import angular from 'angular';
import ValidationDirective from './validation-directive'
import EmailPatternDirective from './email-pattern.directive'

const module = angular.module('resi.form-validation', ['resi.configuration', 'resi.common', 'resi.resources']);

ValidationDirective(module)
EmailPatternDirective(module)

export default module.name
