;(function (undefined) {
	'use strict'

	angular.module('resi.login').factory('PasswordValidation', PasswordValidation)

	function PasswordValidation() {
		var REGEX_SPECIAL_CHARACTER_OR_NUMBER_REQUIRED =
				/(?=.*[\!\"\§\$\%\&\/\(\)\{\}\[\]\=\?\.\,\:\;\-\\\_\#\+\*<>0123456789]).+/,
			REGEX_UPPER_LOWER_CASE_REQUIRED = /(?=.*[a-z])(?=.*[A-Z]).+/,
			REGEX_UMLAUTS = /(?!.*[äÄöÖüÜß]).+/

		return {
			hasText: hasText,
			minLength: minlength,
			containsSpecialCharacterOrNumber: containsSpecialCharacterOrNumber,
			containsLowerAndUpperCase: containsLowerAndUpperCase,
			doesNotContainUmlauts: doesNotContainUmlauts
		}

		function hasText(value) {
			return value !== undefined && value.trim() !== ''
		}

		function minlength(value) {
			return value.length >= 6
		}

		function containsSpecialCharacterOrNumber(value) {
			return REGEX_SPECIAL_CHARACTER_OR_NUMBER_REQUIRED.test(value)
		}

		function containsLowerAndUpperCase(value) {
			return REGEX_UPPER_LOWER_CASE_REQUIRED.test(value)
		}

		function doesNotContainUmlauts(value) {
			return REGEX_UMLAUTS.test(value)
		}
	}
})(undefined)
