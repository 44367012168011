;(function () {
	'use strict'

	angular.module('resi.admin').directive('dbYearFilter', ['SessionData', YearFilter])

	function YearFilter(SessionData) {
		return {
			restrict: 'E',
			scope: {
				year: '=',
				keyname: '@'
			},
			template: require('./year-filter.html').default,
			link: function (scope, element, attrs) {
				var thisYear = new Date().getFullYear()
				var nextYear = thisYear + 1
				scope.yearFilters = [
					{
						name: 'Alle Jahre',
						year: ''
					},
					{
						name: thisYear,
						year: thisYear.toString()
					},
					{
						name: nextYear,
						year: nextYear.toString()
					}
				]

				scope.year = scope.yearFilters[0]

				var year = SessionData.get(attrs.keyname)

				if (year) {
					for (var i = 0; i < scope.yearFilters.length; i++) {
						if (scope.yearFilters[i].year == year) {
							scope.year = scope.yearFilters[i]
						}
					}
				}

				scope.$watch('year', function (currentFilter) {
					if (currentFilter.year && currentFilter.year !== '') {
						SessionData.put(attrs.keyname, currentFilter.year)
					} else {
						SessionData.remove(attrs.keyname)
					}
				})
			}
		}
	}
})()
