;(function () {
	'use strict'

	angular
		.module('resi.booking.payment')
		.factory('PaymentService', ['$http', 'backendEntryPoint', PaymentService])

	function PaymentService($http, backendEntryPoint) {
		return {
			checkoutPayment: function (bookingNumber) {
				return $http
					.post(backendEntryPoint + '/payment/checkout', {
						bookingNumber: bookingNumber
					})
					.then(function (response) {
						return response.data
					})
			},
			commitPayment: function (accountCode, bookingNumber, paymentId) {
				return $http
					.post(backendEntryPoint + '/payment/commit', {
						accountCode: accountCode,
						bookingNumber: bookingNumber,
						paymentId: paymentId
					})
					.then(function (response) {
						return response.data
					})
			},
			payWithCoupon: function (accountCode, bookingNumber, coupon) {
				return $http
					.post(backendEntryPoint + '/payment/with-coupon', {
						accountCode: accountCode,
						bookingNumber: bookingNumber,
						coupon: coupon
					})
					.then(function (response) {
						return response.data
					})
			}
		}
	}
})()
