
'use strict';
import moment from 'moment'

function MessagesService($rootScope, $log) {
	'ngInject';
	init();

	return {
		info: createInfo,
		success: createSuccess,
		error: createError,
		warn: createWarning,
		clear: clear,
		dismiss: dismissMessage
	};

	function init() {
		clear();
	}

	function clear() {
		$rootScope.alerts = [];
	}

	function createInfo(text) {
		displayError(createAlert('info', text));
		$log.info(text);
	}

	function createSuccess(text) {
		displayError(createAlert('success', text));
	}

	function createWarning(text) {
		displayError(createAlert('warning', text));
		$log.warn(text);
	}

	function createError(text) {
		console.log(text);
		displayError(createAlert('danger', text));
		$log.error(text);
	}

	function calculateReversedPosition(index) {
		return $rootScope.alerts.length - index - 1;
	}

	function dismissMessage(index) {
		$rootScope.alerts.splice(calculateReversedPosition(index), 1);
	}

	function displayError(error) {
		$rootScope.alerts.push(error);
	}

	function createAlert(type, text) {
		return {
			type: type,
			timestamp: moment().format('HH:mm:ss'),
			msg: text
		};
	}
}

export default MessagesService
