;(function () {
	'use strict'

	angular.module('resi.booking').factory('SeatService', seatService)

	function seatService() {
		return {
			formatSeat: formatSeat,
			convertSeat: convertSeat
		}
	}

	function formatSeat(seat) {
		if (!seat) {
			return ''
		}
		return 'Wagen ' + seat.coachNr + ', Platz ' + seat.seatNr
	}

	function convertSeat(seat, trip) {
		return {
			departure: trip.departure || trip.departureTime,
			arrival: trip.arrival || trip.arrivalTime,
			trainNumber: seat.trainNumber,
			seatNr: seat.seatNr,
			coachNr: seat.coachNr,
			seatClass: seat.seatClass || trip.seatClass
		}
	}
})()
