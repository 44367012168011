;(function () {
	'use strict'

	angular.module('resi.login').controller('LoginCtrl', ['$rootScope',
    'loginService',
    '$state',
    'MessagesService',
    '$log',
    'accessService',
    '$location',
    '$window', LoginCtrl])

	function LoginCtrl(
		$rootScope,
		loginService,
		$state,
		MessagesService,
		$log,
		accessService,
		$location,
		$window
	) {
		var vm = this

		vm.failedLoginCount = 0
		vm.login = loginUser

		if ($state.current.name.indexOf('login.access-denied') != -1) {
			MessagesService.error('Authentifizierung ist erforderlich')
		}

		function loginUser() {
			loginService
				.login(vm.username, vm.password)
				.then(redirectAfterSuccessfulLogin, processError)

			function redirectAfterSuccessfulLogin() {
				if ($rootScope.returnToState) {
					$state.go(
						$rootScope.returnToState.name,
						$rootScope.returnToState.params
					)
					delete $rootScope.returnToState
				} else {
					if (accessService.hasRole('ADMIN_AREA')) {
							$window.location.href = `${$location.protocol()}://${$location.host()}:${$location.port()}/verwaltung/index.html`
					} else {
						$state.go('account-selection')
					}
				}
			}

			function processError(result) {
				var error = result
				if (error.cause) {
					switch (error.cause) {
						case 'CredentialsExpiredException':
							$state.go('login.credentials-expired', { username: vm.username })
							break
						case 'LockedException':
							MessagesService.error(
								'Ihr Benutzerkonto ist gesperrt. Bitte fordern Sie unter "Ich habe mein Passwort vergessen" ein' +
									' neues Passwort an. Bei weiteren Fragen wenden Sie sich bitte an den Kundensupport.'
							)
							break
						default:
							MessagesService.error(
								'Fehler bei der Anmeldung. Prüfen Sie Ihre Zugangsdaten und versuchen Sie es erneut.'
							)
							vm.failedLoginCount += 1
					}
				}

				$log.error('login error', error)
			}
		}
	}
})()
