'use strict';
import angular from 'angular';


function clearMessagesInterceptor($q, MessagesService) {
	return {
		'request': clearMessagesBeforeRequest,
		'response': doNothing
	};

	function clearMessagesBeforeRequest(config) {
		if (config.method !== 'GET') {
			MessagesService.clear();
		}
		return config;
	}

	function doNothing(response) {
		return response || $q.when(response);
	}
}

angular.module('resi.interceptors').factory('clearMessagesInterceptor', ['$q', 'MessagesService', clearMessagesInterceptor]);

