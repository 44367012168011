'use strict'
import angular from 'angular'
import AdminCtrl from './admin.controller'

angular
	.module('resi.admin')
	.controller('AdminController', ['$scope', '$state', AdminCtrl])
	.factory('RegionResource', ['$resource', 'backendEntryPoint', RegionResource])
	.config(['$stateProvider', function config($stateProvider) {
		$stateProvider.state('admin', {
			url: '/admin',
			controller: 'AdminController',
			template: require('./admin.html').default,
			data: {
				pageTitle: 'Administration',
				protected: true
			}
		})
	}])

function RegionResource($resource, backendEntryPoint) {
	return $resource(backendEntryPoint + '/admin/region')
}
