;(function () {
	'use strict'

	angular
		.module('resi.booking')
		.directive(
			'appDeviatingValidityDaysNotice',
			appDeviatingValidityDaysNoticeFactory
		)

	//@ngInject
	function appDeviatingValidityDaysNoticeFactory() {
		return {
			restrict: 'E',
			scope: {},
			bindToController: {
				validityDays: '=',
				requestedDays: '=',
				onChange: '&'
			},
			controller: ['$scope', AppDeviatingValidityDaysNoticeController],
			controllerAs: '$ctrl',
			template: require('./deviating-validity-days-notice.directive.html')
				.default
		}
	}

	// @ngInject
	function AppDeviatingValidityDaysNoticeController($scope) {
		var $ctrl = this
		$ctrl.deviatingDays = []
		$scope.$watchGroup(
			['$ctrl.validityDays', '$ctrl.requestedDays'],
			function () {
				calculateDeviatingDays()
			}
		)

		function calculateDeviatingDays() {
			$ctrl.deviatingDays = $ctrl.requestedDays.filter(function (day) {
				return $ctrl.validityDays.indexOf(day) === -1
			})
			$ctrl.onChange({ $event: $ctrl.deviatingDays })
		}
	}
})()
