;(function () {
	angular
		.module('resi.booking')
		.controller('RegularSeatBookingsController', ['accountCode',
      'Reservation',
      '$log',
      '$state', RegularSeatBookingsController])

	//@ngInject
	function RegularSeatBookingsController(
		accountCode,
		Reservation,
		$log,
		$state
	) {
		var vm = this
		vm.bookings = []
		vm.cancelReservation = cancelReservation
		vm.extend = extend
		vm.isNotCancelled = isNotCancelled
		vm.isRolloutDisclaimerShown = isRolloutDisclaimerShown

		function isNotCancelled(bookingView) {
			return bookingView.booking.status !== 'CANCELLED'
		}

		function isRolloutDisclaimerShown(bookingView) {
			return bookingView.booking.showRolloutDisclaimer === true
		}

		function reloadBookings(accountCode) {
			vm.bookings = Reservation.query({ accountCode: accountCode })
		}

		function cancelReservation(booking) {
			Reservation.delete({ bookingNumber: booking.number }).$promise.finally(
				function () {
					reloadBookings(accountCode)
				}
			)
		}

		function extend(booking, choice) {
			$log.info('extend reservation', booking, choice)
			if (choice === 'without-change-seat') {
				Reservation.extendReservation(
					{ bookingNumber: booking.number },
					{},
					function (response) {
						if (response.requiresPayment) {
							$state.go('payment.start', {
								bookingNumber: response.booking.number
							})
						} else {
							reloadBookings(accountCode)
						}
					}
				)
			} else if (choice === 'change-seat') {
				$state.go('.extend', { bookingNumber: booking.number })
			}
		}

		reloadBookings(accountCode)
	}
})()
