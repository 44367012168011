'use strict'
import angular from 'angular'

import uiRouter from 'angular-ui-router'
import ngResource from 'angular-resource'
import resiSecurity from '@/modules/security'

const angularModule = angular.module('resi.login', [
	'resi.configuration',
	'resi.common',
	'resi.resources',
	resiSecurity,
	ngResource,
	uiRouter
])

var context = require.context('.', true, /^((?!spec).)*\.js$/)

context.keys().forEach(function (key) {
	context(key)
})

export default angularModule.name
