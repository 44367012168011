'use strict'
import angular from 'angular'

angular.module('resi.login').config(['$stateProvider', Routes])

function Routes($stateProvider) {
	// @ngInject
	$stateProvider.state('login', {
		abstract: true,
		template: require('./login-template.html').default
	})

	$stateProvider.state('login.form', {
		url: '/login',
		template: require('./login.html').default,
		controller: 'LoginCtrl',
		controllerAs: 'vm',
		data: {
			pageTitle: 'Login'
		}
	})

	$stateProvider.state('login.credentials-expired', {
		url: '/expired',
		template: require('./credentials-expired.html').default,
		controller: 'CredentialsExpiredController',
		controllerAs: 'vm',
		data: {
			pageTitle: 'Zugangsdaten aktualisieren'
		},
		params: {
			username: undefined
		}
	})

	$stateProvider.state('login.access-denied', {
		url: '/access-denied',
		template: require('./login.html').default,
		controller: 'LoginCtrl',
		controllerAs: 'vm',
		data: {
			pageTitle: 'Login'
		}
	})

	$stateProvider.state('login.sign-up', {
		abstract: true,
		url: '/sign-up',
		template: require('./sign-up.html').default,
		controller: ['$scope', function ($scope) {
			$scope.REGEXP_NUMBER = /^[\w]+$/
		}
]	})

	$stateProvider.state('login.sign-up.form', {
		url: '/form',
		template: require('./sign-up.form.html').default,
		controller: 'SignUpRequestController',
		controllerAs: 'vm',
		data: {
			pageTitle: 'neues Benuterkonto registrieren'
		}
	})

	$stateProvider.state('login.sign-up.password', {
		url: '/register/:token',
		template: require('./sign-up.password.html').default,
		controller: 'SignUpPasswordController',
		controllerAs: 'vm',
		data: {
			pageTitle: 'neues Benutzerkonto registrieren'
		}
	})

	$stateProvider.state('login.sign-up.completed', {
		url: '/completed',
		template: require('./sign-up.completed.html').default,
		data: {
			pageTitle: 'neues Benutzerkonto registrieren'
		}
	})

	$stateProvider.state('login.sign-up.success', {
		template: require('./sign-up.success.html').default,
		data: {
			pageTitle: 'neues Benutzerkonto registrieren'
		},
		controller: ['$timeout', '$state', 'MessagesService', function ($timeout, $state, MessagesService) {
			$timeout(function () {
				MessagesService.info(
					'Das Konto wurde erfolgreich angelegt. Sie können sich jetzt einloggen.'
				)
				$state.go('login.form')
			}, 1000)
		}
]	})

	$stateProvider.state('login.password-lost', {
		url: '/password-lost',
		template: require('./password-lost.html').default,
		controller: 'PasswordLostController',
		controllerAs: 'vm',
		data: {
			pageTitle: 'neues Passwort anfordern'
		}
	})

	$stateProvider.state('login.reset-password', {
		url: '/reset-password/:token',
		template: require('./reset-password.html').default,
		controller: 'ResetPasswordController',
		controllerAs: 'vm',
		data: {
			pageTitle: 'neues Passwort anfordern'
		}
	})
}
