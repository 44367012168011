;(function () {
	'use strict'

	angular.module('resi.booking').directive('dbTripExceptions', dbTripExceptions)

	//@ngInject
	function dbTripExceptions() {
		return {
			restrict: 'E',
			scope: {},
			bindToController: {
				exceptions: '='
			},
			controller: DbTripExceptionsController,
			controllerAs: 'vm',
			template:
				'<span ng-show="vm.exceptions.length" tooltip-html="vm.tooltip">Ausnahmen *</span>'
		}
	}
DbTripExceptionsController.$inject = ['$scope', 'moment']
	// @ngInject
	function DbTripExceptionsController($scope, moment) {
		var vm = this
		$scope.$watch('vm.exceptions', function (exceptions) {
			vm.tooltip = exceptionsToHtml(exceptions)
		})

		function exceptionsToHtml(exceptions) {
			return (exceptions || [])
				.map(function (exception) {
					return moment(exception, 'YYYY-MM-DD').format('dd DD.MM.YYYY')
				})
				.join('<br/>')
		}
	}
})()
