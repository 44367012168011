import angular from 'angular'

angular
	.module('resi.security')
	.factory('authenticationInterceptor', ['$q', 'tokenStorage', '$location', '$rootScope', AuthenticationInterceptor])

function AuthenticationInterceptor($q, tokenStorage, $location, $rootScope) {
	var service = {
		request: request,
		response: response,
		responseError: responseError
	}

	return service

	function request(config) {
		if (config.url.indexOf('api/login') != -1) {
			return config
		}

		var token = tokenStorage.retrieve()

		if (token) {
			config.headers['X-Auth-Token'] = token
		}

		return config
	}

	function response(responseData) {
		if (responseData.headers('X-Auth-Token')) {
			tokenStorage.store(responseData.headers('X-Auth-Token'))
		}

		return responseData || $q.when(responseData)
	}

	function responseError(rejection) {
		function notOurLoginPage() {
			return (
				rejection.config.url.indexOf('login') == -1 &&
				rejection.config.url.indexOf('update-password') == -1
			)
		}

		if (rejection.status === 401 && notOurLoginPage()) {
			tokenStorage.clear()
			$location.path('/access-denied')
		}

		if (rejection.status === 403) {
			$rootScope.$broadcast('alert.error', 'Zugriff verweigert')
		}

		return $q.reject(rejection)
	}
}
