(function () {
	'use strict';
	angular.module('resi.resources')
		.factory('Reservation', ['$resource', 'backendEntryPoint', Reservation]);

  function Reservation($resource, backendEntryPoint) {

    return $resource(backendEntryPoint + '/reservation/stammplatz/:bookingNumber', {}, {
      getPaymentInformation: {
        url: backendEntryPoint + '/reservation/payment',
        method: 'GET'
      },
      confirm: {
        url: backendEntryPoint + '/reservation/stammplatz/:bookingNumber/confirm',
        method: 'POST'
      },
      extendReservation: {
        url: backendEntryPoint + '/reservation/stammplatz/:bookingNumber/extend',
        method: 'POST'
      },
      extendReservationWithSeatChange: {
        url: backendEntryPoint + '/reservation/stammplatz/:bookingNumber/extend-with-seat-change',
        method: 'POST'
      },
      sendConfirmationEmail: {
        url: backendEntryPoint + '/reservation/stammplatz/:bookingNumber/send-booking-confirmation',
        method: 'POST'
      }
    });
  }
})();
