;(function () {
	angular
		.module('resi.booking')
		.factory('dataBookingResolver', ['moment', 'Reservation', dataBookingResolverFactory])

	//@ngInject
	function dataBookingResolverFactory(moment, Reservation) {
		return {
			createForNew: createForNew,
			createForExtend: createForExtend,
			createForRenew: createForExtend
		}

		function createForNew(accountCode, account) {
			return {
				accountCode: accountCode,
				validSince: moment().format('YYYY-MM-DD'),
				validUntil: account.endOfBookingPeriod,
				selectedSeats: {},
				selectedReturnSeats: {}
			}
		}

		function createForExtend(bookingNumber) {
			return Reservation.get({ bookingNumber: bookingNumber }).$promise
		}
	}
})()
