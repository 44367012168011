import '@/styles/libs.scss'
import 'font-awesome/css/font-awesome.css'
import '@/styles/main.scss'
import 'angular-ui-grid/ui-grid.css'
import 'angular-ui-select/select.css'
import 'angular'

import resiBoot from '@/modules/boot/boot.module'
import '@/modules/configuration.js'
import '@/modules/common/common.module.js'
import validationModule from '@/modules/common/form-validation/form-validation.module'
import resiInterceptor from '@/modules/common/interceptor'
import resiResources from '@/modules/common/resources'
import '@/modules/common/other'
import resiLogin from '@/modules/login'
import resiSecurity from '@/modules/security'
import resiAdmin from '@/modules/admin/admin.module'
import filter from '@/modules/filters'
import settings from '@/modules/settings'
import uiRouter from 'angular-ui-router'
import translate from 'angular-translate'
import uiBootstrap from 'angular-ui-bootstrap'
import datePicker from 'bootstrap-ui-datetime-picker'
import recaptcha from 'angular-recaptcha'
import 'angular-confirm/angular-confirm'
import 'angular-ui-grid/ui-grid.min'
import angularMessage from 'angular-messages'
import resiCommon from '@/modules/common/common.module'
import resiConfiguration from '@/modules/configuration'
import resiPages from '@/modules/pages/pages'
import resiBooking from '@/modules/booking'

angular.module('resi', [resiBoot, resiSecurity, resiLogin, resiConfiguration, resiCommon, resiResources, resiInterceptor, uiRouter,
    uiBootstrap, datePicker, 'angular-confirm', translate, filter, 'ui.grid',
    settings, resiPages, resiBooking, recaptcha, angularMessage, validationModule
  ])
  .config(['$urlRouterProvider', setDefaultRoute])
  .config(['$httpProvider', addNetworkInterceptor])
  .config(['$httpProvider', addClearMessagesInterceptor])
  .config(['uibDatepickerConfig', initDatepicker])
  .config(['uibDatepickerPopupConfig', initDatepickerPopup])
  .config(['uiDatetimePickerConfig', initDatetimePicker])
  .run(['$rootScope', '$http', 'backendEntryPoint', 'accessService', 'loginService', '$location', '$confirmModalDefaults', run])
  .run(['$rootScope', 'MessagesService', configureErrorHandling])
  .controller('AppCtrl', ['$scope', 'loginService', AppCtrl])
  .config(['$translateProvider', '$provide', translateConfig]);

//
function setDefaultRoute($urlRouterProvider) {
  // @ngInject
  $urlRouterProvider.otherwise(function ($injector) {
    var loginService = $injector.get('loginService');
    var accessService = $injector.get('accessService');

    if (loginService && loginService.isLoggedIn()) {

      return '/stammplatz-abos';
    }

    return '/login';
  });
}

function addNetworkInterceptor($httpProvider) {
  $httpProvider.interceptors.push('networkInterceptor');
}

function addClearMessagesInterceptor($httpProvider) {
  $httpProvider.interceptors.push('clearMessagesInterceptor');
}

function initDatepicker(uibDatepickerConfig) {
  uibDatepickerConfig.showWeeks = false;
  uibDatepickerConfig.startingDay = 1;
  uibDatepickerConfig.formatYear = 'yyyy';
}

function initDatepickerPopup(uibDatepickerPopupConfig) {
  uibDatepickerPopupConfig.datePickerPopup = 'dd.MM.yyyy';
}

function initDatetimePicker(uiDatetimePickerConfig) {
  uiDatetimePickerConfig.todayText = 'Heute';
  uiDatetimePickerConfig.closeText = 'Schlie&szlig;en';
  uiDatetimePickerConfig.clearText = 'L&ouml;schen';
  uiDatetimePickerConfig.dateFormat = 'dd.MM.yyyy';
  uiDatetimePickerConfig.enableTime = false;
  uiDatetimePickerConfig.showButtonBar = false;
}

function translateConfig($translateProvider, $provide) {
  $translateProvider.useSanitizeValueStrategy('escape');
  $translateProvider.preferredLanguage('de');
  $translateProvider.translations('de', require('./modules/lang-de.json'))

  $provide.decorator('GridOptions', function ($delegate, i18nService) {
    var gridOptions;
    gridOptions = angular.copy($delegate);
    gridOptions.initialize = function (options) {
      var initOptions;
      initOptions = $delegate.initialize(options);
      return initOptions;
    };
    //es is the language prefix you want
    i18nService.setCurrentLang('de');
    return gridOptions;
  });
}

function configureErrorHandling($rootScope, MessagesService) {

  $rootScope.closeAlert = function (index) {
    MessagesService.dismiss(index);
  };

  $rootScope.$on('alert.info', function (event, data) {
    MessagesService.info(data);
  });

  $rootScope.$on('alert.error', function (event, data) {
    MessagesService.error(data);
  });

  $rootScope.$on('alert.clear', function () {
    MessagesService.clear();
  });

  $rootScope.$on('error.http', function (event, data) {
    var text = ' Systemfehler: ' + data.code + ', probieren Sie es erneut oder wenden Sie sich bitte an den Kundenservice.';
    if (data.exception && data.exception.message) {
      text += ' (' + data.exception.message + ')';
    }
    MessagesService.error(text);
  });
  $rootScope.$on('error', function (event, data) {
    MessagesService.error(data.msg);
  });
  $rootScope.$on('$stateChangeSuccess', function () {
    MessagesService.clear();
  });
}

function run($rootScope, $http, backendEntryPoint, accessService, loginService, $location, $confirmModalDefaults) {
  $rootScope.systemInfo = {}
  $http.get(backendEntryPoint + '/version').then(function (data) {
    $rootScope.systemInfo = data;
  });

  $rootScope.logout = function (event) {
    loginService.logout();
    $location.path('/login');

    event.preventDefault();
  };

  $rootScope.currentUser = loginService.currentUser;

  $rootScope.hasRole = accessService.hasRole;

  //$confirmModalDefaults.templateUrl = 'path/to/your/template';
  $confirmModalDefaults.defaultLabels.title = 'Bestätigung';
  $confirmModalDefaults.defaultLabels.ok = 'Ja';
  $confirmModalDefaults.defaultLabels.cancel = 'Nein';
}

function AppCtrl($scope, loginService) {
  var vm = this;
  vm.userIsLoggedIn = loginService.isLoggedIn;

  $scope.$on('$stateChangeSuccess', function (event, toState) {
    if (angular.isDefined(toState.data.pageTitle)) {
      vm.pageTitle = 'Sitzplatzreservierung | ' + toState.data.pageTitle;
    }
    vm.menuCollapsed = true;
  });
}
