'use strict';
import angular from 'angular';

angular.module('resi.resources')
	.factory('Validation', ['$resource', 'backendEntryPoint', function ($resource, backendEntryPoint) {
		return $resource(backendEntryPoint + "/validate", {}, {
			findOverlappedBooking: {
				url: backendEntryPoint + "/validate/overlapped-booking",
				method: 'GET'
			}
		});
	}])
