;(function () {
  angular.module('resi.booking').config(['$stateProvider', routeConfig])

  //@ngInject
  function routeConfig($stateProvider) {
    $stateProvider.state('regular-seat.edit', {
      url: '/neu',
      controller: 'RegularSeatEditController',
      controllerAs: 'vm',
      template: require('./edit.html').default,
      resolve: {
        dataFromStations: ['Stops', function (Stops) {
          return Stops.query().$promise
        }],
        dataTrips: ['dataTripsResolver', function (dataTripsResolver) {
          return dataTripsResolver.createForNew()
        }],
        dataBooking: [
          'dataBookingResolver',
          'accountCode',
          'account',
          function (
            dataBookingResolver,
            accountCode,
            account
          ) {
            return dataBookingResolver.createForNew(accountCode, account)
          }],
        skipCurrentTimetablePeriod: function () {
          return false
        }
      }
    })
  }
})()
