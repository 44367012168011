angular.module('resi.security').factory('tokenStorage', ['$window', TokenStorage])

function TokenStorage($window) {
	var service = {
		store: store,
		storeUser: storeUser,
		retrieve: retrieve,
		retrieveUser: retrieveUser,
		clear: clear
	}

	return service

	function store(token) {
		$window.localStorage.jwtToken = token
	}

	function storeUser(userData) {
		$window.localStorage.jwtUserData = JSON.stringify(userData)
	}

	function retrieve() {
		return $window.localStorage.jwtToken
	}

	function retrieveUser() {
		var rawToken = $window.localStorage.jwtUserData
		return rawToken ? JSON.parse(rawToken) : null
	}

	function clear() {
		delete $window.localStorage.jwtToken
		delete $window.localStorage.jwtUserData
	}
}
