import angular from 'angular'

const resiSecurity = angular.module('resi.security', [])

var context = require.context('.', true, /^((?!spec).)*\.js$/)

context.keys().forEach(function (key) {
	context(key)
})

export default resiSecurity.name
