;(function () {
	angular
		.module('resi.booking')
		.controller('RegularSeatEditController', ['$scope',
      'Stops',
      'dataFromStations',
      'seatSelectionDialog',
      'moment',
      '$state',
      '$log',
      'TripService',
      'SeatService',
      'MessagesService',
      'Validation',
      '$q',
      'Reservation',
      'dataTrips',
      'dataBooking',
      'skipCurrentTimetablePeriod', RegularSeatEditController])

	// @ngInject
	function RegularSeatEditController(
		$scope,
		Stops,
		dataFromStations,
		seatSelectionDialog,
		moment,
		$state,
		$log,
		TripService,
		SeatService,
		MessagesService,
		Validation,
		$q,
		Reservation,
		dataTrips,
		dataBooking,
		skipCurrentTimetablePeriod
	) {
		var vm = this
		vm.outgoingTrips = []
		vm.returnTrips = []
		vm.returnTripsFriday = []

		vm.fromStations = dataFromStations
		vm.toStations = []

		vm.trip = dataTrips.trip
		vm.returnTrip = dataTrips.returnTrip
		vm.returnTripFriday = dataTrips.returnTripFriday

		vm.booking = dataBooking
		vm.abweichendeRueckfahrtFreitag = false
		vm.paymentInformation = true

		vm.selectSeats = selectSeats
		vm.formatSeat = SeatService.formatSeat
		vm.formatDepartureTimeOf = formatDepartureTimeOf
		vm.submit = submit
		vm.isReturnTimeAfterTripArrival = isReturnTimeAfterTripArrival
		vm.switchFriday = switchFriday
		vm.resetFriday = resetFriday

		$scope.$watch('vm.booking.to', reloadTrips)
		$scope.$watch('vm.booking.from', reloadToStations)

		// reset seats when trip changes
		$scope.$watch('vm.trip.trip', function () {
			delete vm.trip.seat
		})
		$scope.$watch('vm.returnTrip.trip', function () {
			delete vm.returnTrip.seat
		})
		$scope.$watch('vm.returnTripFriday.trip', function () {
			delete vm.returnTripFriday.seat
		})

		vm.paymentRequired = function () {
			return vm.paymentInformation.requiresPayment
		}

		vm.isDisabled = isInputDisabled

		function reloadTrips() {
			reloadOutgoingTrips()
			reloadReturnTrips()
			reloadPaymentInformation()
			vm.abweichendeRueckfahrtFreitag = false

			hasOverlappingErrors(
				vm.booking.accountCode,
				vm.booking.validSince,
				vm.booking.validUntil,
				vm.booking.from,
				vm.booking.to
			)
		}

		function resetFriday() {
			if (!vm.returnTrip.trip) {
				vm.returnTripFriday = {}
				vm.abweichendeRueckfahrtFreitag = false
			}
		}

		function reloadOutgoingTrips() {
			vm.outgoingTrips = []
			vm.trip = {}

			if (!vm.booking.from || !vm.booking.to) {
				return
			}

			$log.debug('reload from trips')
			vm.outgoingTrips = TripService.findTripsForMondayToFriday(
				vm.booking.from,
				vm.booking.to,
				vm.booking.validSince,
				vm.booking.validUntil,
				skipCurrentTimetablePeriod,
				vm.booking.accountCode
			)
		}

		function reloadReturnTrips() {
			vm.returnTrips = []
			vm.returnTripsFriday = []

			vm.returnTrip = {}
			vm.returnTripFriday = {}

			if (!vm.booking.from || !vm.booking.to) {
				return
			}

			$log.debug('reload return trips')
			if (vm.abweichendeRueckfahrtFreitag) {
				vm.returnTrips = TripService.findTripsForMondayToThursday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
				vm.returnTripsFriday = TripService.findTripsOnFriday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
			} else {
				vm.returnTrips = TripService.findTripsForMondayToFriday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
			}
		}

		function reloadPaymentInformation() {
			vm.paymentInformation = {}

			if (!vm.booking.from || !vm.booking.to || !vm.booking.accountCode) {
				return
			}
			$log.debug('reload payment information')
			vm.paymentInformation = TripService.getPaymentInformation(
				vm.booking.accountCode,
				vm.booking.from.evaNumber,
				vm.booking.to.evaNumber,
				vm.booking.validUntil
			)
		}

		function switchFriday() {
			$log.debug('switch friday')
			var oldReturnTrip = angular.copy(vm.returnTrip)
			reloadReturnTrips()
			if (oldReturnTrip.trip) {
				vm.returnTrips.$promise.then(function () {
					var foundTrip = vm.returnTrips.filter(function (x) {
						return (
							x.departure === oldReturnTrip.trip.departure &&
							x.trainNr === oldReturnTrip.trip.trainNr
						)
					})[0]
					if (foundTrip) {
						vm.returnTrip = oldReturnTrip
						vm.returnTrip.trip = foundTrip
					}
				})
			}
		}

		function isReturnTimeAfterTripArrival(time) {
			if (!time) {
				return false
			}

			if (!vm.trip.trip) {
				return true
			}

			var tripArrival = timeStringToMoment(vm.trip.trip.arrival),
				returnTripDeparture = timeStringToMoment(time.departure)
			return returnTripDeparture.isAfter(tripArrival)

			function timeStringToMoment(timestring) {
				return moment(timestring, 'HH:mm')
			}
		}

		function reloadToStations(from) {
			if (vm.booking) {
				vm.booking.to = null
			}
			if (!from) {
				return
			}
			$log.debug('reload to', angular.toJson(from))
			vm.toStations = Stops.query({ from: from.evaNumber })
		}

		function selectSeats(from, to, trip) {
			if (trip.trip) {
				var days = null
				if (!vm.abweichendeRueckfahrtFreitag) {
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
				} else if (trip === vm.returnTripFriday) {
					days = ['FRIDAY']
				} else {
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY']
				}
				seatSelectionDialog
					.open(
						from,
						to,
						vm.booking,
						vm.booking.accountCode,
						false,
						trip.seat,
						trip.trip.trainNr,
						days
					)
					.then(function (seat) {
						$log.debug('a seat was selected', seat)
						trip.seat = seat
					})
			}
		}

		function formatDepartureTimeOf(trip) {
			return trip.departure + (trip.exceptions.length ? '*' : '')
		}

		function submit(form) {
			MessagesService.clear()

			if (
				hasInputErrors(
					vm.booking.validSince,
					vm.booking.validUntil,
					null,
					vm.booking.from,
					vm.booking.to
				)
			) {
				return
			}

			if (!vm.trip.seat && !vm.returnTrip.seat) {
				MessagesService.error('Sie müssen einen Sitzplatz auswählen.')
				return
			}

			if (vm.abweichendeRueckfahrtFreitag && !vm.returnTripFriday.seat) {
				MessagesService.error(
					'Wählen Sie für Freitag einen Sitz aus oder entfernen Sie die Option: Abweichende Rückfahrt am Freitag'
				)
				return
			}

			hasOverlappingErrors(
				vm.booking.accountCode,
				vm.booking.validSince,
				vm.booking.validUntil,
				vm.booking.from,
				vm.booking.to
			).then(function (result) {
				if (result) {
					return
				}

				if (form.$invalid) {
					return
				}

				;['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].forEach(
					function (day) {
						if (vm.trip.trip && vm.trip.seat) {
							vm.booking.selectedSeats[day] = SeatService.convertSeat(
								vm.trip.seat,
								vm.trip.trip
							)
						}
						if (vm.returnTrip.trip && vm.returnTrip.seat) {
							if (day === 'FRIDAY' && vm.abweichendeRueckfahrtFreitag) {
								vm.booking.selectedReturnSeats[day] = SeatService.convertSeat(
									vm.returnTripFriday.seat,
									vm.returnTripFriday.trip
								)
							} else {
								vm.booking.selectedReturnSeats[day] = SeatService.convertSeat(
									vm.returnTrip.seat,
									vm.returnTrip.trip
								)
							}
						}
					}
				)

				Reservation.save(vm.booking, function (response) {
					var booking = response.booking
					$log.debug(
						'Created booking',
						booking.number,
						booking.status,
						response.requiresPayment
					)
					if (response.requiresPayment) {
						$state.go('payment.start', { bookingNumber: booking.number })
					} else {
						$state.go(
							'regular-seat',
							{ nextTimetableHint: booking.rollout === 'SHORTENED' },
							{ reload: true }
						)
					}
				})
			})
		}

		function hasInputErrors(validSince, validUntil, bookingNr, from, to) {
			if (!validSince || !validUntil || !from || !to) {
				MessagesService.error('Sie müssen eine Verbindung auswählen')
				return true
			}
			function convertDate(date) {
				return angular.isString(date) ? new Date(date) : date
			}

			validSince = convertDate(validSince)
			validUntil = convertDate(validUntil)

			var today = new Date()
			today.setHours(0, 0, 0, 0)
			validSince.setHours(0, 0, 0, 0)
			validUntil.setHours(23, 59, 59, 999)

			if (!bookingNr && validUntil < today) {
				MessagesService.error(
					'Ihr Abo ist nicht mehr gültig. Vor einer Buchung müssen Sie es verlängern.'
				)
				return true
			} else if (validSince > validUntil) {
				MessagesService.error(
					'"Datum bis" muss größer oder gleich "Datum von" sein.'
				)
				return true
			} else {
				return false
			}
		}

		function hasOverlappingErrors(
			accountCode,
			validSince,
			validUntil,
			from,
			to,
			bookingNr
		) {
			if (!(accountCode && validSince && validUntil && from && to)) {
				return
			}
			var result = $q.defer()
			Validation.findOverlappedBooking(
				{
					accountCode: accountCode,
					startDate: moment(validSince).format('YYYY-MM-DD'),
					endDate: moment(validUntil).format('YYYY-MM-DD'),
					from: from.evaNumber,
					to: to.evaNumber,
					bookingNr: bookingNr
				},
				function () {
					result.resolve(false)
				},
				function (response) {
					MessagesService.error(response.data.message)
					result.resolve(true)
				}
			)

			return result.promise
		}

		function isInputDisabled() {
			return false
		}
	}
})()
