;(function () {
	'use strict'

	angular.module('resi.boot').run(['$rootScope', logRouterErrorRun])

	function logRouterErrorRun($rootScope) {
		$rootScope.$on(
			'$stateChangeError',
			function (event, toState, toParams, fromState, fromParams, error) {
				console.error('Error routing to state', toState, error)
			}
		)
	}
})()
