'use strict';
export default function dbAccount() {
  return function (account) {

    if (!account || !account.accountCode) {
      return 'ACCOUNT: -';
    }

    var accountInfo = 'ACCOUNT: ' + account.accountCode;

    if (!account.subscriptionCode) {
      return accountInfo;
    }

    var MAX_SUBSCRIPTION_LENGTH = 13;
    if (account.subscriptionCode.length >= MAX_SUBSCRIPTION_LENGTH) {
      return accountInfo + ', ABO: ' + account.subscriptionCode.substring(0, MAX_SUBSCRIPTION_LENGTH - 1) + "…";
    }

    return accountInfo + ', ABO: ' + account.subscriptionCode;
  };
}
