'use strict'
import angular from 'angular'
import resiConfiguration from '@/modules/configuration'
import resiCommon from '@/modules/common/common.module'
import resiSecurity from '@/modules/security'
import resiDirectives from '@/modules/common/other/directives'
import 'angular-confirm/angular-confirm'
import uiBootstrap from 'angular-ui-bootstrap'
import 'angular-ui-grid/ui-grid.min'

import {
	ConfirmEmailController,
	SettingsController
} from './settings.controller.js'

export default angular
	.module('resi.settings', [
		'angular-confirm',
		'ui.router',
		resiConfiguration,
		resiCommon,
		resiSecurity,
		resiDirectives,
		uiBootstrap,
		'ui.grid'
	])
	.config(['$stateProvider', Routes]).name

require('./settings.service')

SettingsController.$inject = [
  '$scope',
  '$confirm',
  '$http',
  'backendEntryPoint',
  'loginService',
  '$state',
  '$stateParams',
  'SettingsService',
  'MessagesService',
  'UserProfile',
  'accessService'
]
ConfirmEmailController.$inject = [
  '$stateParams',
  '$state',
  'SettingsService',
  'MessagesService',
  '$filter'
]
function Routes($stateProvider) {
	$stateProvider
		.state('settings', {
			url: '/settings',
			controller: SettingsController,
			controllerAs: 'vm',
			template: require('./settings.html').default,
			data: {
				pageTitle: 'Meine Einstellungen',
				protected: true
			},
			params: {
				info: null,
				error: null
			}
		})
		.state('settings-confirm-email', {
			url: '/settings/confirm-email/:token',
			controller: ConfirmEmailController,
			template: require('./settings.html').default,
			data: {
				pageTitle: 'Meine Einstellungen',
				protected: true
			}
		})
}
