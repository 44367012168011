'use strict'

import dbTicket from '../db-ticket'

export default angular
	.module('resi.booking.payment', [dbTicket, 'ui.router', 'resi.security'])
	.config(['$stateProvider', Routes]).name

var context = require.context('.', true, /^((?!spec).)*\.js$/)
context.keys().forEach(function (key) {
	context(key)
})

function Routes($stateProvider, $state) {
	$stateProvider.state('payment', {
		url: '/payment/:bookingNumber',
		abstract: true,
		data: {
			pageTitle: 'Zahlen'
		},
		resolve: {
			booking: ['SessionData', 'Reservation', '$stateParams', function (SessionData, Reservation, $stateParams) {
        return Reservation.get({ bookingNumber: $stateParams.bookingNumber })
      }
      ]		},
		template: require('./payment.html').default
	})
	$stateProvider.state('payment.start', {
		url: '/start',
		controller: 'PaymentStartController',
		controllerAs: 'vm',
		template: require('./payment-start.html').default
	})
	$stateProvider.state('payment.commit', {
		url: '/commit?payId',
		controller: 'PaymentCommitController',
		controllerAs: 'vm',
		template: require('./payment-commit.html').default,
		resolve: {
			paymentId: ['$stateParams', function ($stateParams) {
        return $stateParams.payId
      }
      ]		}
	})
	$stateProvider.state('payment.cancel', {
		url: '/cancel',
		controller: 'PaymentCancelController',
		controllerAs: 'vm',
		template: require('./payment-cancel.html').default
	})
	$stateProvider.state('payment.simulate', {
		url: '/simulate',
		controller: 'SimulateController',
		controllerAs: 'vm',
		resolve: {
			bookingNumber: ['$stateParams', function ($stateParams) {
        return $stateParams.bookingNumber
      }
      ]		},
		template: require('./simulate.html').default
	})
}
