'use strict';

// !! Überschreibt den eingebauten E-Mail Validator von Angular JS
// vergl. https://docs.angularjs.org/guide/forms
function dbEmailPatternDirective() {
	var REGEXP_EMAIL = /^[A-Za-z0-9_.-]{1,63}@[A-Za-z0-9_.-]{2,63}\.[A-Za-z]{2,5}$/i;

	return {
		require: '?ngModel',
		link: linkFn
	};

	function linkFn(scope, element, attrs, ngModel) {
		if (ngModel && ngModel.$validators.email) {
			ngModel.$validators.email = dbEmailValidator;
		}

		function dbEmailValidator(modelValue) {
			return ngModel.$isEmpty(modelValue) || REGEXP_EMAIL.test(modelValue);
		}
	}
}

export default function install(angularModule) {
	angularModule.directive('dbEmailPattern', dbEmailPatternDirective)
}
