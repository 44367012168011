;(function () {
	angular
		.module('resi.booking')
		.controller('RegularSeatController', ['$scope',
      '$state',
      'accountCode',
      'account',
      'nextTimetableHint',
      'MessagesService', RegularSeatController])

	//@ngInject
	function RegularSeatController(
		$scope,
		$state,
		accountCode,
		account,
		nextTimetableHint,
		MessagesService
	) {
		var vm = this
		$scope.accountCode = accountCode

		vm.account = account

		if (nextTimetableHint) {
			MessagesService.warn(
				'Die Buchung war nur im aktuellen Fahrplan erfolgreich, da der Zug eine andere Abfahrt hat. Wählen Sie auf dem Ticket "Im neuen Fahrplan buchen", um einen anderen Zug im neuen Fahrplan auszuwählen.'
			)
		}

		vm.newBooking = function () {
			$state.go('.edit', { accountCode: accountCode })
		}
	}
})()
