'use strict';
import angular from 'angular'
import DelayedWatch from './delayed-watch.service'
import MessagesService from './messages.service'
import SessionDataService from './session-data.service'
import moment from 'moment'

export default angular.module('resi.common', [])
	.factory('MessagesService', ['$rootScope', '$log', MessagesService])
	.service('SessionData', ['$window', SessionDataService])
	.factory('DelayedWatch', ['$timeout', DelayedWatch])
	.constant('localStorage', window.localStorage)
	.constant('moment', moment)
	.name;
