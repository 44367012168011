export default angular.module('resi.booking').config(['$stateProvider', routeConfig]).name

var context = require.context('.', true, /^((?!spec).)*\.js$/)
context.keys().forEach(function (key) {
	context(key)
})

//@ngInject
function routeConfig($stateProvider) {
	$stateProvider.state('regular-seat', {
		url: '/stammplatz?account&nextTimetableHint',
		data: {
			protected: true
		},
		resolve: {
			accountCode: ['$stateParams', 'accessService', 'loginService', function ($stateParams, accessService, loginService) {
				if (accessService.hasRole('ROLE_USER')) {
					return loginService.currentUser().accountCode
				} else {
					return $stateParams.account
				}
			}],
			account: ['AccountResource', 'accountCode', function (AccountResource, accountCode) {
				return AccountResource.get({ code: accountCode }).$promise
			}],
			nextTimetableHint: ['$stateParams', function ($stateParams) {
				return $stateParams.nextTimetableHint === 'true'
			}
]		},
		views: {
			'': {
				controller: 'RegularSeatController',
				controllerAs: 'vm',
				template: require('./regular-seat.html').default
			},
			'bookings@regular-seat': {
				controller: 'RegularSeatBookingsController',
				controllerAs: 'vm',
				template: require('./regular-seat-bookings.html').default
			}
		}
	})
}
