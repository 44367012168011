;(function () {
	'use strict'

	angular
		.module('resi.booking.db-ticket')
		.directive('dbTicketTrip', dbTicketTrip)

	// @ngInject
	function dbTicketTrip() {
		return {
			require: '^^ticket',
			scope: {},
			bindToController: {
				trip: '=',
				target: '@'
			},
			controller: DbTicketTripController,
			controllerAs: 'vm',
			template: require('./db-ticket-trip.html').default
		}
	}

	// @ngInject
	function DbTicketTripController() {
		var vm = this
	}
})()
