'use strict';
import angular from 'angular';

angular.module('resi.resources')
	.factory('UserProfile', ['$http', '$q', 'backendEntryPoint', 'AccountResource', 'loginService', '$stateParams', 'accessService', 'MessagesService', UserProfileFactory]);

function UserProfileFactory($http, $q, backendEntryPoint, AccountResource, loginService, $stateParams, accessService, MessagesService) {
	return {
		getSeatClass: getSeatClass,
		setSeatClass: setSeatClass,
		getSubscriptionCode: getSubscriptionCode,
		setSubscriptionCode: setSubscriptionCode
	};

	function getSeatClass() {
		return $q(loadAccount);

		function loadAccount(resolve, reject) {
			function aboSuccess(data) {
				resolve(data.seatClass);
			}

			function aboError() {
				MessagesService.clear();
				MessagesService.error('Abo nicht gefunden, prüfen Sie bitte Ihre Einstellungen.');
				reject();
			}

			if (accessService.hasRole('ROLE_USER')) {
				return AccountResource.get({code: loginService.currentUser().accountCode},
					aboSuccess,
					aboError);
			} else {
				return AccountResource.get({code: $stateParams.account},
					aboSuccess,
					aboError);
			}
		}
	}

	function setSeatClass(seatClass) {
		if ('FIRST' !== seatClass && 'SECOND' !== seatClass) {
			$q.reject();
		}
		return $http.post(backendEntryPoint + '/user-profile/seat-class', {
			'seatClass': seatClass
		});
	}

	function getSubscriptionCode() {
		return $q(loadAccount);

		function loadAccount(resolve, reject) {
			function aboSuccess(data) {
				resolve(data.subscriptionCode);
			}

			function aboError() {
				MessagesService.clear();
				MessagesService.error('Abo nicht gefunden, prüfen Sie bitte Ihre Einstellungen.');
				reject();
			}

			if (accessService.hasRole('ROLE_USER')) {
				return AccountResource.get({code: loginService.currentUser().accountCode},
					aboSuccess,
					aboError);
			} else {
				return AccountResource.get({code: $stateParams.account},
					aboSuccess,
					aboError);
			}
		}
	}

	function setSubscriptionCode(subscriptionCode) {
		return $http.post(backendEntryPoint + '/user-profile/subscription', {
			'subscriptionCode': subscriptionCode
		});
	}

}
