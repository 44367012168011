;(function () {
	angular
		.module('resi.booking')
		.factory('dataTripsResolver', dataTripsResolverFactory)

	//@ngInject
	function dataTripsResolverFactory() {
		return {
			createForNew: createForNew,
			createForExtend: createForExtend,
			createForRenew: createForRenew
		}

		function createForNew() {
			return {
				trip: {},
				returnTrip: {},
				returnTripFriday: {}
			}
		}

		function createForExtend(bookingView) {
			return {
				trip: bookingView.booking.trip.MONDAY
					? buildTrip(bookingView.booking.trip.MONDAY)
					: {},
				returnTrip: bookingView.booking.returnTrip.MONDAY
					? buildTrip(bookingView.booking.returnTrip.MONDAY)
					: {},
				returnTripFriday: isSameTrainAndSeat(
					bookingView.booking.returnTrip.MONDAY,
					bookingView.booking.returnTrip.FRIDAY
				)
					? buildTrip(bookingView.booking.returnTrip.FRIDAY)
					: {}
			}
		}

		function createForRenew(bookingView) {
			var extendedBookingView = createForExtend(bookingView)
			extendedBookingView.skip = true
			return extendedBookingView
		}

		function buildTrip(tripDay) {
			var seat = {
				trainNr: tripDay.seat.trainNr,
				coachNr: tripDay.seat.coachNr,
				seatNr: tripDay.seat.seatNr,
				seatClass: tripDay.seatClass,
				trainNumber: {
					stammzugNr: tripDay.displayTrainNr,
					trainNr: tripDay.seat.trainNr
				}
			}
			var trip = {
				arrival: tripDay.arrivalTime,
				departure: tripDay.departureTime,
				trainNr: tripDay.displayTrainNr,
				exceptions: []
			}
			return { trip: trip, seat: seat }
		}

		function isSameTrainAndSeat(monday, friday) {
			return (
				monday &&
				friday &&
				(monday.seat.trainNr != friday.seat.trainNr ||
					monday.seat.seatNr != friday.seat.seatNr ||
					monday.seat.coachNr != friday.seat.coachNr)
			)
		}
	}
})()
