"use strict";

/* @ngInject */
function validateTimespan(Fahrten, Validation, MessagesService) {
	return {
		require: '^form',
		restrict: 'E',
		scope: {
			validSince: '=',
			validUntil: '='
		},
		link: function ($scope, element, attrs, form) {

			function callValidate() {
				validate(convertDate($scope.validSince), convertDate($scope.validUntil));
			}

			$scope.$watch('validUntil', callValidate);

			function convertDate(date) {
				return angular.isString(date) ? new Date(date) : date;
			}

			function validate(validSince, validUntil) {

				form.$setValidity('timespan', false);

				if (!validSince || !validUntil) {
					return;
				}

				var today = new Date();
				today.setHours(0, 0, 0, 0);
				validSince.setHours(0, 0, 0, 0);
				validUntil.setHours(23, 59, 59, 999);

				if (validUntil < today) {
					MessagesService.error('Ihr Abo ist nicht mehr gültig. Bei einer Buchung müssen Sie es verlängern.');
				} else if (validSince > validUntil) {
					MessagesService.error('"Datum bis" muss größer oder gleich "Datum von" sein.');
				} else {
					form.$setValidity('timespan', true);
				}
			}
		}
	};
}

/* @ngInject */
function DbShowErrors($timeout) {
	return {
		restrict: 'A',
		require: '^form',
		link: function (scope, el, attrs, formCtrl) {
			// find the text box element, which has the 'name' attribute
			var inputEl = el[0].querySelector("[name]");
			var glyphiconNgEl = angular.element('<span class="glyphicon glyphicon-warning-sign form-control-feedback ng-hide"></span>');

			// convert the native text box element to an angular element
			var inputNgEl = angular.element(inputEl);
			// get the name on the text box so we know the property to check
			// on the form controller
			var inputName = inputNgEl.attr('name');

			// Fehler Icon einfuegen
			inputNgEl.after(glyphiconNgEl);

			// only apply the has-error class after the user leaves the text box
			inputNgEl.bind('blur', function () {
				var hasErrors = formCtrl[inputName].$invalid && formCtrl[inputName].$dirty;
				glyphiconNgEl.toggleClass('ng-hide', !hasErrors);
				el.toggleClass('has-error has-feedback', hasErrors);
			});

			scope.$on('show-errors-reset', function () {
				$timeout(function () {
					el.removeClass('has-error has-feedback');
					if (glyphiconNgEl) {
						glyphiconNgEl.addClass('ng-hide');
					}
					formCtrl[inputName].$setPristine();
				}, 0, false);
			});

			scope.$on('show-errors-check-validity', function () {
				el.toggleClass('has-error', formCtrl[inputName].$invalid);
			});
		}
	};
}

function DbTime() {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, el, attrs, ctrl) {

			function validTime(value) {
				var valid = /^\d\d:\d\d$/.test(value);
				if (!valid) {
					return false;
				}
				var parts = value.split(':');
				var hour = parts[0];
				if (hour < 0 || hour > 23) {
					return false;
				}
				var minute = parts[1];
				if (minute < 0 || minute > 59) {
					return false;
				}
				return true;
			}

			ctrl.$parsers.unshift(function (value) {
				var valid = validTime(value);

				ctrl.$setValidity('invalidTime', valid);

				return valid ? value : undefined;
			});

			// add a formatter that will process each time the value
			// is updated on the DOM element.
			ctrl.$formatters.unshift(function (value) {
				var valid = validTime(value);

				ctrl.$setValidity('invalidTime', valid);

				return value;
			});
		}
	};
}

function validateStations() {
	return {
		restrict: 'A',
		require: 'ngModel',
		scope: {
			validateStations: '='
		},
		link: function (scope, element, attr, ctrl) {
			function validateStations(modelValue, viewValue) {
				if (scope.validateStations === undefined || scope.validateStations.indexOf(modelValue) === -1) {
					return false;
				} else {
					return true;
				}
			}

			ctrl.$validators.stationValidator = validateStations;

			scope.$watch('validateStations', function () {
				ctrl.$setValidity("stationValidator", validateStations(ctrl.$modelValue, ctrl.$viewValue));
				ctrl.$validate();
			});
		}
	};
}

function validateRelations(backendEntryPoint, $http, $q) {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attr, ctrl) {
			function validateRelations(modelValue, viewValue) {
				return $http.post(backendEntryPoint + '/validate/relation/' + scope.tripForm.from.$modelValue.evaNumber + '/' + modelValue.evaNumber, {})
					.then(
						function () {
							return $q.resolve();
						},
						function (response) {
							scope.$parent.relationValidatorError = response.data.message;
							return $q.reject(response.data.message);
						}
					);
			}

			ctrl.$asyncValidators.relationValidator = validateRelations;
		}
	};
}

export default function install(angularModule) {
	angularModule
		.directive('validateTimespan', ['Fahrten', 'Validation', 'MessagesService', validateTimespan])
		.directive('dbShowErrors', ['$timeout', DbShowErrors])
		.directive('dbTime', DbTime)
		.directive('validateStations', validateStations)
		.directive('validateRelations', ['backendEntryPoint', '$http', '$q', validateRelations]);

}
