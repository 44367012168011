'use strict';
import angular from 'angular';

angular.module('resi.interceptors').factory('networkInterceptor', ['$q', '$log', 'MessagesService', '$translate', networkInterceptor]);

function networkInterceptor($q, $log, MessagesService, $translate) {
  return {
    'response': function (response) {
      return response || $q.when(response);

    },
    'responseError': function (rejection) {
      var HTTP_401_UNAUTHORIZED = 401;
      var HTTP_403_FORBIDDEN = 403;
      var HTTP_404_NOT_FOUND = 404;
      var HTTP_409_CONFLICT = 409;
      var HTTP_410_GONE = 410;

      if (angular.isDefined(rejection.data.key) && rejection.data.key) {
        $translate('error.' + rejection.data.key).then(function (text) {
          MessagesService.error(text + (rejection.data.context || ''));
        });
      } else if (rejection.status == HTTP_404_NOT_FOUND) {
        console.log('Daten wurden nicht gefunden', rejection.status, rejection.data);

        if (!angular.isDefined(rejection.data.cause)) {
          MessagesService.error('Daten wurden nicht gefunden.');
        }
      } else if (rejection.status == HTTP_410_GONE) {
        console.log('Link abgelaufen', rejection.status, rejection.data);

        if (!angular.isDefined(rejection.data.cause)) {
          MessagesService.error('Dieser Link ist abgelaufen. Bitte fordern Sie einen neuen Link an.');
        }
      } else if ([HTTP_401_UNAUTHORIZED, HTTP_403_FORBIDDEN, HTTP_409_CONFLICT].indexOf(rejection.status) != -1) {
        console.log('http error', rejection.status, rejection.data);

        if (!angular.isDefined(rejection.data.cause)) {
          MessagesService.error(
            'Wir haben leider ein technisches Problem. Sie können sich gerne an den Support wenden. Den Kontakt finden Sie in der Fußzeile.');
        } else if (angular.isDefined(rejection.data.message)) {
          MessagesService.error(rejection.data.message)
        }
      }else{
        console.error('http error', rejection.status, rejection.data);
        MessagesService.error(
          'Wir haben leider ein technisches Problem. Sie können sich gerne an den Support wenden. Den Kontakt finden Sie in der Fußzeile.');
      }

      return $q.reject(rejection);
    }
  };
}
