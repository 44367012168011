
export const DbSeatDescriptionController = function ($scope, SEAT_SYMBOLS) {
  $scope.SEAT_SYMBOLS = SEAT_SYMBOLS
}
export default function dbSeatDescription() {
	return {
		scope: {
			item: '='
		},
		template: require('./db-seat-description.html').default,
		controller: ['$scope', 'SEAT_SYMBOLS', DbSeatDescriptionController]
	}
}
