;(function () {
	'use strict'

	angular
		.module('resi.security')
		.controller('CredentialsExpiredController', ['loginService',
      'MessagesService',
      '$state',
      '$stateParams', CredentialsExpiredController])

	function CredentialsExpiredController(
		loginService,
		MessagesService,
		$state,
		$stateParams
	) {
		var vm = this

		vm.username = $stateParams.username
		vm.changePassword = changePassword

		function changePassword() {
			loginService
				.updatePassword(vm.username, vm.password, vm.newPassword)
				.then(redirectToLogin)
				.catch(function (error) {
					MessagesService.error(
						'Authentifizierung schlug fehl: ' + error.message
					)
				})

			function redirectToLogin() {
				MessagesService.success('Ihr Passwort wurde geändert.')
				loginService.logout()
				$state.go('login.form')
			}
		}
	}
})()
