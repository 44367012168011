;(function () {
	'use strict'

	var specialCharactersOrNumberRequiredDirectiveId =
			'dbSpecialCharacterOrNumberRequired',
		lowerUpperCaseRequiredDirectiveId = 'dbLowerUpperCaseRequired',
		umlautsNotAllowedDirectiveId = 'dbDoesNotContainUmlauts',
		fulfilledGuidelinesInspectorDirectiveId = 'dbPasswordGuidelinesInspector'

	angular
		.module('resi.security')
		.directive(
			specialCharactersOrNumberRequiredDirectiveId,
			['PasswordValidation', specialCharacterOrNumberRequiredDirective]
		)
		.directive(
			lowerUpperCaseRequiredDirectiveId,
			['PasswordValidation', lowerUpperCaseRequiredDirective]
		)
		.directive(umlautsNotAllowedDirectiveId, ['PasswordValidation', doesNotContainUmlauts])
		.directive(
			fulfilledGuidelinesInspectorDirectiveId,
			['$log', fulfilledGuidelinesInspectorDirective]
		)

	// @ngInject
	function specialCharacterOrNumberRequiredDirective(PasswordValidation) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: link
		}

		function link(scope, element, attrs, ngModel) {
			ngModel.$validators[specialCharactersOrNumberRequiredDirectiveId] =
				validateSpecialCharacterOrNumber

			function validateSpecialCharacterOrNumber(modelValue, viewValue) {
				return PasswordValidation.containsSpecialCharacterOrNumber(
					modelValue || viewValue
				)
			}
		}
	}

	// @ngInject
	function lowerUpperCaseRequiredDirective(PasswordValidation) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: link
		}

		function link(scope, element, attrs, ngModel) {
			ngModel.$validators[lowerUpperCaseRequiredDirectiveId] =
				validateLowerUpperCase

			function validateLowerUpperCase(modelValue, viewValue) {
				return PasswordValidation.containsLowerAndUpperCase(
					modelValue || viewValue
				)
			}
		}
	}

	// @ngInject
	function doesNotContainUmlauts(PasswordValidation) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: link
		}

		function link(scope, element, attrs, ngModel) {
			ngModel.$validators[umlautsNotAllowedDirectiveId] = doesNotContainUmlauts

			function doesNotContainUmlauts(modelValue, viewValue) {
				return PasswordValidation.doesNotContainUmlauts(modelValue || viewValue)
			}
		}
	}

	// @ngInject
	function fulfilledGuidelinesInspectorDirective($log) {
		var ATTR_INSPECT_INPUT = 'inspect'

		return {
			restrict: 'E',
			template: require('./password-guidelines-inspector.html').default,
			require: '^form',
			scope: {},
			link: link,
			controller: function () {},
			controllerAs: 'inspector',
			bindToController: true
		}

		function link(scope, element, attrs, ngForm) {
			$log.debug('link', attrs)

			var attr = attrs[ATTR_INSPECT_INPUT]
			if (!attr) {
				return
			}

			if (ngForm[attr]) {
				scope.inspector.model = ngForm[attr]
			}
		}
	}
})()
