;(function () {
	'use strict'

	angular.module('resi.booking').factory('TripService', ['Fahrten', 'Reservation', tripService])

	function tripService(Fahrten, Reservation) {
		return {
			findTripsForMondayToFriday: findTripsForMondayToFriday,
			findTripsForMondayToThursday: findTripsForMondayToThursday,
			findTripsOnFriday: findTripsOnFriday,
			getPaymentInformation: getPaymentInformation
		}

		function findTripsForMondayToFriday(
			from,
			to,
			validSince,
			validUntil,
			skipCurrentTimetablePeriod,
			accountCode
		) {
			return findTrips(
				from,
				to,
				validSince,
				validUntil,
				['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
				skipCurrentTimetablePeriod || false,
				accountCode
			)
		}

		function findTripsForMondayToThursday(
			from,
			to,
			validSince,
			validUntil,
			skipCurrentTimetablePeriod,
			accountCode
		) {
			return findTrips(
				from,
				to,
				validSince,
				validUntil,
				['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY'],
				skipCurrentTimetablePeriod || false,
				accountCode
			)
		}

		function findTripsOnFriday(
			from,
			to,
			validSince,
			validUntil,
			skipCurrentTimetablePeriod,
			accountCode
		) {
			return findTrips(
				from,
				to,
				validSince,
				validUntil,
				['FRIDAY'],
				skipCurrentTimetablePeriod || false,
				accountCode
			)
		}

		function findTrips(
			from,
			to,
			validSince,
			validUntil,
			daysOfWeek,
			skipCurrentTimetablePeriod,
			accountCode
		) {
			var search = {
				from: from.evaNumber,
				to: to.evaNumber,
				departure: validSince,
				arrival: validUntil,
				daysOfWeek: daysOfWeek,
				skip: skipCurrentTimetablePeriod,
				account: accountCode,
				preventCaching: new Date()
			}

			return Fahrten.findStammplatzConnections(search)
		}

		function getPaymentInformation(
			accountCode,
			fromEvaNumber,
			toEvaNumber,
			bookingValidUntil
		) {
			var query = {
				accountCode: accountCode,
				fromEvaNumber: fromEvaNumber,
				toEvaNumber: toEvaNumber,
				bookingValidUntil: bookingValidUntil
			}

			return Reservation.getPaymentInformation(query)
		}
	}
})()
