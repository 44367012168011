'use strict'

angular
	.module('resi.booking.db-ticket')
	.directive('dbTicket', dbTicket)
	.controller('ModalExtendController', ['$scope', '$uibModalInstance', 'dataBooking', ModalExtendController])

function ModalExtendController($scope, $uibModalInstance, dataBooking) {
	$scope.cancel = cancel
	$scope.extend = extend
	$scope.booking = dataBooking

	function cancel() {
		$uibModalInstance.dismiss()
	}

	function extend(choice) {
		$uibModalInstance.close(choice)
	}
}

// @ngInject
function dbTicket() {
	return {
		scope: {
			bookingView: '=',
			extendBooking: '&',
			cancelBooking: '&',
			displayOnly: '='
		},
		controller: DbTicketController,
		template: require('./db-ticket.html').default
	}
}
DbTicketController.$inject = ['dbBookingFilter', '$uibModal', 'moment', '$scope']
// @ngInject
function DbTicketController(dbBookingFilter, $uibModal, moment, $scope) {
	$scope.booking = $scope.bookingView.booking
	$scope.filteredBooking = dbBookingFilter($scope.booking)
	$scope.canExtend = canExtend
	$scope.canCancel = canCancel
	$scope.isExtendable = isExtendable
	$scope.isFollowingBookingPresent = isFollowingBookingPresent
	$scope.openExtendReservationDialog = openExtendReservationDialog

	////////////////////////////////////////////////////

	function canExtend() {
		return (
			$scope.booking.status === 'BOOKED' &&
			$scope.booking.validUntil <= moment().add(2, 'months').format('YYYY-MM-DD')
		)
	}

	function canCancel() {
		return (
			$scope.booking.status === 'INCOMPLETE' || /^BOOKED/.test($scope.booking.status)
		)
	}

	function isExtendable() {
		return $scope.booking.rollout !== 'SHORTENED'
	}

	function isFollowingBookingPresent() {
		return $scope.bookingView.followingBooking
	}

	function openExtendReservationDialog(booking) {
		var instance = $uibModal.open({
			controller: 'ModalExtendController',
			templateUrl: 'extend-reservation-dialog.html',
			resolve: { dataBooking: booking }
		})
		instance.result.then(function (choice) {
			$scope.extendBooking({ $event: choice })
		})
	}
}
