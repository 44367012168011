'use strict'

import resiCommon from '@/modules/common/common.module'

export default angular.module('resi.booking.db-ticket', [
	'resi.filters',
	resiCommon
]).name

var context = require.context('.', true, /^((?!spec).)*\.js$/)
context.keys().forEach(function (key) {
	context(key)
})
