;(function () {
	angular
		.module('resi.booking.account-selection')
		.controller('AccountSelectionController', ['$state', 'accessService', 'AccountResource', AccountSelectionController])

	//@ngInject
	function AccountSelectionController($state, accessService, AccountResource) {
		var vm = this

		if (accessService.hasRole('ROLE_USER')) {
			$state.go('regular-seat')
		}

		vm.goToRegularSeatsForSubscription = goToRegularSeatsForSubscription

		function goToRegularSeatsForSubscription() {
			AccountResource.get({ code: vm.accountCode }, function () {
				$state.go('regular-seat', {
					account: vm.accountCode
				})
			})
		}
	}
})()
