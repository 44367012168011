'use strict'

export const DbLevelEditorController = function ($scope, $log, SEAT_SYMBOLS, $uibModal, $q) {
  let vm = this

  vm.action = {
    action: 'EMPTY',
    seatClass: 2,
    mode: 'select'
  }
  vm.selectedItem = null

  vm.selectSeat = selectSeat
  vm.changeSize = changeSize
  vm.SEAT_SYMBOLS = SEAT_SYMBOLS

  $scope.$watch('level', updateCellCount)
  $scope.$watch('vm.action.mode', resetSelectedItem)

  ///////////////////////////////////

  function updateCellCount() {
    $log.debug('updateCellCount')
    if ($scope.level.rows.length > 0) {
      vm.action.rows = $scope.level.rows.length
      vm.action.cols = $scope.level.rows[0].length
    } else {
      vm.action.rows = 4
      vm.action.cols = 20
    }
    resizeCoach({
      rows: vm.action.rows,
      cols: vm.action.cols
    })
  }

  function selectSeat(item) {
    $log.debug(vm.action, item)
    let promise
    if (vm.action.mode === 'layer') {
      if (vm.action.action === 'EMPTY') {
        $log.debug("can't add a layer for EMPTY")
        return
      }
      if (item.type !== '=') {
        // convert to a layered item
        let clonedItem = angular.copy(item)
        delete clonedItem.views // just to be sure
        resetItem(item)
        item.type = '='
        item.views = clonedItem.type !== 'EMPTY' ? [clonedItem] : []
      }
      let newItem = {}
      promise = actionToItem(newItem).then(function () {
        item.views.unshift(newItem)
        return newItem
      })
    } else if (vm.action.mode === 'replace') {
      promise = actionToItem(item)
    } else if (vm.action.mode === 'select') {
      vm.selectedItem = item
      promise = $q.when(item)
    }
    promise.then(function (item) {
      $log.debug('changed item', item)
    })
  }

  function resetItem(item) {
    angular.extend(item, {
      attributes: null,
      coachNr: null,
      direction: null,
      reserved: false,
      seatClass: null,
      seatNr: null
    })
    delete item.views
    return item
  }

  function seatNrExists(seatNr) {
    if (!seatNr) {
      return false
    }
    let intSeatNr = parseInt(seatNr)
    if (intSeatNr === parseInt(lastAction.seatNr)) {
      return false
    }
    return $scope.level.rows.some(function (row) {
      return row.some(function (item) {
        return parseInt(item.seatNr) === intSeatNr
      })
    })
  }

  function actionToItem(item) {
    if (vm.action.action === 'SEAT') {
      if (item.type === 'SEAT') {
        lastAction.seatClass = parseInt(item.seatClass)
        lastAction.seatNr = parseInt(item.seatNr)
        lastAction.direction = item.direction
        lastAction.attr = undefined

        // In einigen Altdaten gibt es noch Attribute 'Gang', 'Tisch',... , die nicht mehr benötigt werden. Für den Editor sind
        // nur die Attribute '...Reservierung' relevant.
        // TODO: Dieser Filter kann entfallen, wenn kein Wagenlayout mehr als 1 Attribut pro Sitzplatzsymbol enthält.
        item.attributes = item.attributes.filter(function (attr) {
          return attr.indexOf('Reservierung') > 0
        })

        if (item.attributes && item.attributes.length > 0) {
          lastAction.attr = item.attributes[0]
        }
      } else {
        lastAction.seatNr = null
      }
      return openSeatDialog().then(function (seatAction) {
        resetItem(item)
        item.direction = seatAction.direction
        item.seatClass = seatAction.seatClass
        item.seatNr = seatAction.seatNr
        item.type = 'SEAT'
        item.attributes = []
        if (seatAction.attr) {
          item.attributes.push(seatAction.attr)
        }
        return item
      })
    } else {
      resetItem(item)
      item.type = vm.action.action
      return $q.when(item)
    }
  }

  let lastAction = {}

  function openSeatDialog() {
    let instance = $uibModal.open({
      template: require('./seat-dialog.html').default,
      controller: /* @ngInject */ [
        '$scope',
        'seatNrExists',
        'lastAction',
        function (
          $scope,
          seatNrExists,
          lastAction
        ) {
          $scope.seatNrExists = seatNrExists
          $scope.action = lastAction
        }
      ],
      resolve: {
        seatNrExists: $q.when(seatNrExists),
        lastAction: $q.when(angular.copy(lastAction))
      }
    })
    return instance.result.then(function (action) {
      $log.debug('create seat', action)
      lastAction = angular.copy(action)
      return action
    })
  }

  function changeSize() {
    let instance = $uibModal.open({
      template: require('./size-dialog.html').default,
      controller: ['$scope', 'currentSize', function ($scope, currentSize) {
        $scope.originalSize = currentSize
        $scope.size = angular.copy(currentSize)
      }],
      resolve: {
        currentSize: $q.when({
          rows: vm.action.rows,
          cols: vm.action.cols
        })
      }
    })
    instance.result.then(function (size) {
      vm.action.rows = size.rows
      vm.action.cols = size.cols
      resizeCoach(size)
    })
  }

  function resizeCoach(size) {
    while ($scope.level.rows.length > size.rows) {
      $scope.level.rows.pop()
    }
    while ($scope.level.rows.length < size.rows) {
      $scope.level.rows.push([])
    }
    $scope.level.rows.forEach(function (cols) {
      while (cols.length > size.cols) {
        cols.pop()
      }
      while (cols.length < size.cols) {
        cols.push(
          resetItem({
            type: 'EMPTY'
          })
        )
      }
    })
  }

  function resetSelectedItem(mode) {
    if (mode !== 'select') {
      vm.selectedItem = null
    }
  }
}

export default function dbLevelEditor() {
  return {
    scope: {
      level: '=',
      disabled: '='
    },
    controller: ['$scope', '$log', 'SEAT_SYMBOLS', '$uibModal', '$q', DbLevelEditorController],
    controllerAs: 'vm',
    template: require('./db-level-editor.html').default
  }
}
