'use strict'
import angular from 'angular'
import resiSecurity from '@/modules/security'

const resiBoot = angular.module('resi.boot', [resiSecurity])

var context = require.context('.', true, /^((?!spec).)*\.js$/)
context.keys().forEach(function (key) {
	context(key)
})

export default resiBoot.name
