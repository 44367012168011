;(function () {
	angular
		.module('resi.booking')
		.controller('RegularSeatExtendController', ['$scope',
      'Stops',
      'dataFromStations',
      'dataToStations',
      'seatSelectionDialog',
      'moment',
      '$state',
      '$log',
      'TripService',
      'SeatService',
      'MessagesService',
      'Validation',
      '$q',
      'Reservation',
      'dataTrips',
      'dataBooking',
      'formStateHandler',
      'reservationHandler',
      'skipCurrentTimetablePeriod', RegularSeatExtendController])

	//@ngInject
	function RegularSeatExtendController(
		$scope,
		Stops,
		dataFromStations,
		dataToStations,
		seatSelectionDialog,
		moment,
		$state,
		$log,
		TripService,
		SeatService,
		MessagesService,
		Validation,
		$q,
		Reservation,
		dataTrips,
		dataBooking,
		formStateHandler,
		reservationHandler,
		skipCurrentTimetablePeriod
	) {
		var vm = this
		vm.outgoingTrips = []
		vm.returnTrips = []
		vm.returnTripsFriday = []

		vm.fromStations = dataFromStations
		vm.toStations = dataToStations

		vm.trip = dataTrips.trip
		vm.returnTrip = dataTrips.returnTrip
		vm.returnTripFriday = dataTrips.returnTripFriday

		vm.isExtendForm = true

		vm.booking = dataBooking.booking
		vm.abweichendeRueckfahrtFreitag = !!dataTrips.returnTripFriday.trip
		vm.paymentInformation = true

		vm.selectSeats = selectSeats
		vm.formatSeat = SeatService.formatSeat
		vm.formatDepartureTimeOf = formatDepartureTimeOf
		vm.submit = submit
		vm.switchFriday = switchFriday

		vm.paymentRequired = function () {
			return vm.paymentInformation.requiresPayment
		}

		vm.isDisabled = formStateHandler.isInputDisabled

		if (skipCurrentTimetablePeriod) {
			$scope.$watch('vm.trip.trip', function () {
				delete vm.trip.seat
			})

			$scope.$watch('vm.returnTrip.trip', function () {
				delete vm.returnTrip.seat
			})

			$scope.$watch('vm.returnTripFriday.trip', function () {
				delete vm.returnTripFriday.seat
			})
		}

		reloadTrips()

		function reloadTrips() {
			vm.outgoingTrips = TripService.findTripsForMondayToFriday(
				vm.booking.from,
				vm.booking.to,
				vm.booking.validSince,
				vm.booking.validUntil,
				skipCurrentTimetablePeriod,
				vm.booking.accountCode
			)

			if (vm.abweichendeRueckfahrtFreitag) {
				vm.returnTrips = TripService.findTripsForMondayToThursday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
				vm.returnTripsFriday = TripService.findTripsOnFriday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
			} else {
				vm.returnTrips = TripService.findTripsForMondayToFriday(
					vm.booking.to,
					vm.booking.from,
					vm.booking.validSince,
					vm.booking.validUntil,
					skipCurrentTimetablePeriod,
					vm.booking.accountCode
				)
			}
		}

		function switchFriday() {
			reloadTrips()
		}

		function selectSeats(from, to, trip) {
			if (trip.trip) {
				var days = null
				if (!vm.abweichendeRueckfahrtFreitag) {
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
				} else if (trip === vm.returnTripFriday) {
					days = ['FRIDAY']
				} else {
					days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY']
				}
				seatSelectionDialog
					.open(
						from,
						to,
						vm.booking,
						vm.booking.accountCode,
						skipCurrentTimetablePeriod,
						trip.seat,
						trip.trip.trainNr,
						days
					)
					.then(function (seat) {
						$log.debug('a seat was selected', seat)
						trip.seat = seat
					})
			}
		}

		function formatDepartureTimeOf(trip) {
			return trip.departure + (trip.exceptions.length ? '*' : '')
		}

		function submit(form) {
			MessagesService.clear()

			if (!vm.trip.seat && !vm.returnTrip.seat) {
				MessagesService.error('Sie müssen einen Sitzplatz auswählen.')
				return
			}

			if (vm.abweichendeRueckfahrtFreitag && !vm.returnTripFriday.seat) {
				MessagesService.error(
					'Wählen Sie für Freitag einen Sitz aus oder entfernen Sie die Option: Abweichende Rückfahrt am Freitag'
				)
				return
			}

			var reservationRequest

			if (skipCurrentTimetablePeriod) {
				reservationRequest = vm.booking
			} else {
				reservationRequest = {
					bookingNumber: skipCurrentTimetablePeriod
						? undefined
						: vm.booking.number
				}
			}

			angular.extend(reservationRequest, {
				selectedSeats: {},
				selectedReturnSeats: {},
				skip: skipCurrentTimetablePeriod
			})

			;['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].forEach(
				function (day) {
					if (vm.trip.trip && vm.trip.seat) {
						reservationRequest.selectedSeats[day] = SeatService.convertSeat(
							vm.trip.seat,
							vm.trip.trip
						)
					}
					if (vm.returnTrip.trip && vm.returnTrip.seat) {
						if (day === 'FRIDAY' && vm.abweichendeRueckfahrtFreitag) {
							reservationRequest.selectedReturnSeats[day] =
								SeatService.convertSeat(
									vm.returnTripFriday.seat,
									vm.returnTripFriday.trip
								)
						} else {
							reservationRequest.selectedReturnSeats[day] =
								SeatService.convertSeat(vm.returnTrip.seat, vm.returnTrip.trip)
						}
					}
				}
			)

			reservationHandler.execute(
				{ bookingNumber: reservationRequest.bookingNumber },
				reservationRequest,
				function (response) {
					var booking = response.booking
					$log.debug(
						'Extended booking',
						booking.number,
						booking.status,
						response.requiresPayment
					)
					if (response.requiresPayment) {
						$state.go('payment.start', { bookingNumber: booking.number })
					} else {
						$state.go(
							'regular-seat',
							{ nextTimetableHint: false },
							{ reload: true }
						)
					}
				}
			)
		}
	}
})()
