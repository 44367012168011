;(function () {
	angular
		.module('resi.booking')
		.controller('MockSeatSelectionController', [
      '$scope',
      'seatSelectionData',
      '$uibModalInstance', MockSeatSelectionController])

	// @ngInject
	function MockSeatSelectionController(
		$scope,
		seatSelectionData,
		$uibModalInstance
	) {
		$scope.seatClass = seatSelectionData.seatClass
		$scope.trainNr = seatSelectionData.trainNr
		$scope.coaches = seatSelectionData.coaches
		$scope.currentTrip = {
			selectedSeat: seatSelectionData.selectedSeat
		}
		$scope.days = seatSelectionData.days

		$scope.cancel = cancel
		$scope.select = select

		function cancel() {
			$uibModalInstance.dismiss()
		}

		function select() {
			$uibModalInstance.close($scope.currentTrip.selectedSeat)
		}

		$scope.selectLevel = selectLevel
		$scope.formatCoach = formatCoach
		$scope.formatAvailableSeats = formatAvailableSeats
		$scope.selectSeat = selectSeat
		$scope.hasSeatsAvailable = hasSeatsAvailable

		$scope.formOptions = {
			allSeatAttributes: ['Tisch', 'Gang', 'Ruhe', 'Fenster']
		}

		///////////////////////////////////////////////////////////////////

		function activate() {
			$scope.selectLevel($scope.coaches[0], $scope.coaches[0].levels[0])

			if ($scope.currentTrip.selectedSeat) {
				var seatLocation = findSeatLocation(
					$scope.currentTrip.selectedSeat.trainNr,
					$scope.currentTrip.selectedSeat.coachNr,
					$scope.currentTrip.selectedSeat.seatNr
				)
				if (seatLocation) {
					$scope.selectLevel(seatLocation.coach, seatLocation.level)
					$scope.selectSeat(seatLocation.item)
				} else {
					selectAnyLevelWithAvailableSeats()
				}
			} else {
				selectAnyLevelWithAvailableSeats()
			}
		}

		activate()

		//REFACTORING NOETIG ANFANG

		function findSeatLocation(trainNr, coachNr, seatNr) {
			var result = null
			$scope.coaches.forEach(function (coach) {
				if (coach.coachNr == coachNr) {
					coach.levels.forEach(function (level) {
						level.rows.forEach(function (row) {
							row.forEach(function (item) {
								if (
									item.type == 'SEAT' &&
									item.seatNr == seatNr &&
									item.trainNr == trainNr
								) {
									result = { coach: coach, level: level, item: item }
								}
							})
						})
					})
				}
			})
			return result
		}

		function selectAnyLevelWithAvailableSeats() {
			$scope.coaches.forEach(function (coach) {
				coach.levels.forEach(function (level) {
					if ($scope.hasSeatsAvailable(level)) {
						$scope.selectLevel(coach, level)
						return
					}
				})
			})
		}

		//REFACTORING NOETIG ENDE

		function hasSeatsAvailable(level) {
			var availableSeats =
				$scope.seatClass === 'FIRST'
					? level.availableSeatsFirstClass
					: level.availableSeatsSecondClass
			return availableSeats > 0
		}

		function selectSeat(seat) {
			$scope.currentTrip.selectedSeat = angular.extend(
				{
					trainNumber: { stammzugNr: $scope.trainNr, trainNr: seat.trainNr }
				},
				seat
			)
		}

		function selectLevel(coach, level) {
			$scope.selectedCoach = coach
			$scope.$parent.selectedLevelIndex = coach.levels.indexOf(level)
			$scope.$parent.coach = coach
		}

		function formatCoach(coach, levelIndex) {
			if ([coach, levelIndex].some(angular.isUndefined)) {
				return ''
			}

			var messageParts = ['Wagen ', coach.coachNr, ': ']
			if (coach.levels.length == 2) {
				messageParts.push(
					coach.levels[levelIndex].level === 'UPPER' ? 'Oben' : 'Unten',
					' / '
				)
			}
			messageParts.push(formatAvailableSeats(coach.levels[levelIndex]))
			return messageParts.join('')
		}

		function formatAvailableSeats(level) {
			if (!level) {
				return ''
			}
			var availableSeats =
				$scope.seatClass === 'FIRST'
					? level.availableSeatsFirstClass
					: level.availableSeatsSecondClass
			return availableSeats + ' freie Plätze'
		}
	}
})()
