import angular from 'angular';
import accountFilter from './account.filter'
import moment from 'moment'

moment.locale('de')

let filterModule = angular.module('resi.filters', [])
  .filter('dbAccount', accountFilter)

export default filterModule.name

filterModule
  .filter('dbDate', ['$filter', function ($filter) {
    return function (input) {
      if (!input) {
        return input;
      }
      return $filter('date')(input, 'dd.MM.yyyy');
    };
  }])
  .filter('dbTime', function () {
    return function (input) {
      if (!input) {
        return input;
      }

      var timeParts = input.match(/^(\d+):(\d+)/);
      if (timeParts) {
        return timeParts[1] + ':' + timeParts[2];
      } else {
        return moment(input).format('HH:mm');
      }
    };
  })
  .filter('dbSelectedSeat', function () {
    return function (seat, train) {
      return 'Zug: ' + train.trainNr +
        ', Reisezeit: ' + train.departure + ' - ' + train.arrival + ' Uhr' +
        ', Wagen: ' + seat.coachNr +
        ', Platz: ' + seat.seatNr +
        ', Klasse: ' + seat.seatClass;
    };
  })
  .filter('dbBookedSeat', ['$filter', function ($filter) {
    return function (trip) {
      return 'Zug: ' + trip.trainNr +
        ', Reisezeit: ' + $filter('dbTime')(trip.departureTime) + ' - ' + $filter('dbTime')(trip.arrivalTime) + ' Uhr' +
        ', Wagen: ' + trip.seat.coachNr +
        ', Platz: ' + trip.seat.seatNr +
        ', Klasse: ' + trip.seatClass;
    };
  }])
  .filter('dbReverse', function () {
    return function (input) {
      return input.slice().reverse();
    };
  })
  .filter('dbYesNo', function () {
    return function (input) {
      return input ? 'Ja' : 'Nein';
    };
  })
  .filter('dbNotEmpty', function () {
    return function (input) {
      return input && !angular.equals(input, {}) ? true : false;
    };
  })
  .filter('dbReservationStatus', function () {
    return function (input) {
      switch (input) {
      case 'BOOKED':
        return 'Reserviert';
      case 'BOOKED_PARTLY':
        return 'Reserviert im aktuellen Fahrplan';
      case 'INCOMPLETE':
        return 'Warnung: inkonsistente Buchung';
      case 'CANCELLED':
        return 'Storniert';
      case 'CANCELLED_INCOMPLETE':
        return 'Warnung: inkonsistente Buchung';
      }
    };
  })
  .filter('dbBooking', function () {

    var dayOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

    function formatDate(date) {
      return date.format('DD.MM.YYYY');
    }

    function toDate(date) {
      return moment(date, 'YYYY-MM-DD');
    }

    function dayRange(currentDay, startOfDateRange) {
      if (startOfDateRange) {
        return moment().isoWeekday(startOfDateRange).format('dddd') + " - " + moment().isoWeekday(currentDay).format('dddd');
      } else {
        return moment().isoWeekday(currentDay).format('dddd');
      }
    }

    function dayRangeShort(currentDay, startOfDateRange) {
      if (startOfDateRange) {
        return moment().isoWeekday(startOfDateRange).format('dd') + " - " + moment().isoWeekday(currentDay).format('dd');
      } else {
        return moment().isoWeekday(currentDay).format('dd');
      }
    }

    function daysOfWeek(currentDay, startOfDateRange) {
      if (!startOfDateRange) {
        startOfDateRange = currentDay;
      }

      var returnArray = [];
      for (var i = startOfDateRange; i <= currentDay; i++) {
        returnArray.push(dayOfWeek[i - 1]);
      }
      return returnArray;
    }

    function buildMap(reservation, dayRange, dayRangeShort, daysOfWeek) {
      reservation.day = dayRange;
      reservation.dayShort = dayRangeShort;
      reservation.daysOfWeek = daysOfWeek;

      return reservation;
    }

    function mergeTrips(trip) {

      var result = [];
      if (!trip) {
        return result;
      }
      var dayOfTrainNr = null;
      for (var day = 1; day < 6; day++) {
        var reservation = trip[dayOfWeek[day - 1]];
        if (!reservation) {
          continue;
        }
        var currentTrip = trip[dayOfWeek[day]];
        if (day < 5 && currentTrip) {
          if (reservation.seat.trainNr !== currentTrip.seat.trainNr ||
            reservation.seat.coachNr !== currentTrip.seat.coachNr || reservation.seat.seatNr !== currentTrip.seat.seatNr) {
            result.push(buildMap(reservation, dayRange(day, dayOfTrainNr), dayRangeShort(day, dayOfTrainNr),
              daysOfWeek(day, dayOfTrainNr)));
            dayOfTrainNr = null;
          } else if (dayOfTrainNr === null) {
            dayOfTrainNr = day;
          }
        } else {
          result.push(buildMap(reservation, dayRange(day, dayOfTrainNr), dayRangeShort(day, dayOfTrainNr),
            daysOfWeek(day, dayOfTrainNr)));
        }
      }
      return result;
    }

    return function (input) {

      var validSince = toDate(input.validSince);
      var validUntil = toDate(input.validUntil);
      var validRange;
      if (validSince.year == validUntil.year) {
        validRange = validSince.format('DD.MM') + ' - ' + formatDate(validUntil);
      } else {
        validRange = formatDate(validSince) + ' - ' + formatDate(validUntil);
      }

      return {
        subscriptionNr: input.subscriptionNr,
        validSince: input.validSince,
        validUntil: input.validUntil,
        valid: validRange,
        from: input.from,
        to: input.to,
        trip: mergeTrips(input.trip),
        returnTrip: mergeTrips(input.returnTrip)
      };
    };
  })
  .filter('reservation', function () {
    return function (reservation) {

      if (!reservation) {
        return '';
      }
      return reservation.day + ' um ' +
        reservation.departureTime.substring(0, 5) + ' Uhr, ' +
        'Zug: ' + reservation.seat.trainNr + ', ' +
        'Sitzplatz: ' + reservation.seat.seatNr;
    };
  })
  .filter('reverse', ReverseArray);

function ReverseArray() {
  return function (array) {
    return array.slice().reverse();
  };
}
