;(function () {
  angular.module('resi.booking').config(['$stateProvider', routeConfig])

  //@ngInject
  function routeConfig($stateProvider) {
    $stateProvider.state('regular-seat.extend', {
      url: '/extend/:bookingNumber',
      controller: 'RegularSeatExtendController',
      controllerAs: 'vm',
      template: require('../edit/edit.html').default,
      resolve: {
        dataFromStations: ['Stops', function (Stops) {
          return Stops.query().$promise
        }],
        dataToStations: function () {
          return []
        },
        dataTrips: /* @ngInject */ ['dataBooking', 'dataTripsResolver', function (dataBooking, dataTripsResolver) {
          return dataTripsResolver.createForExtend(dataBooking)
        }],
        dataBooking: /* @ngInject */ [
          'dataBookingResolver',
          '$stateParams',
          function (
            dataBookingResolver,
            $stateParams
          ) {
            return dataBookingResolver.createForExtend($stateParams.bookingNumber)
          }],
        formStateHandler: /* @ngInject */ ['extendFormStateHandler',
          function (extendFormStateHandler) {
            return extendFormStateHandler
          }],
        skipCurrentTimetablePeriod: function () {
          return false
        },
        reservationHandler: ['Reservation', function (Reservation) {
          return {
            execute: function () {
              Reservation.extendReservationWithSeatChange.apply(this, arguments)
            }
          }
        }
        ]
      }
    })

    $stateProvider.state('regular-seat.renew', {
      url: '/renew/:bookingNumber',
      controller: 'RegularSeatExtendController',
      controllerAs: 'vm',
      template: require('../edit/edit.html').default,
      resolve: {
        dataFromStations: /* @ngInject */ ['dataBooking', function (dataBooking) {
          return [dataBooking.booking.from]
        }],
        dataToStations: /* @ngInject */ ['dataBooking', function (dataBooking) {
          return [dataBooking.booking.to]
        }],
        dataTrips: /* @ngInject */ ['dataBooking', 'dataTripsResolver', function (dataBooking, dataTripsResolver) {
          return dataTripsResolver.createForRenew(dataBooking)
        }],
        dataBooking: /* @ngInject */ [
          'dataBookingResolver',
          '$stateParams',
          function (
            dataBookingResolver,
            $stateParams
          ) {
            return dataBookingResolver.createForRenew($stateParams.bookingNumber)
          }],
        formStateHandler: /* @ngInject */ [
          'renewFormStateHandler',
          function (renewFormStateHandler) {
            return renewFormStateHandler
          }],
        skipCurrentTimetablePeriod: function () {
          return true
        },
        reservationHandler: ['Reservation',
          function (Reservation) {
            return {
              execute: function () {
                var args = Array.prototype.slice.call(arguments, 1)
                Reservation.save.apply(this, args)
              }
            }
          }
        ]
      }
    })
  }
})()
