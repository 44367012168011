'use strict'

export { ConfirmEmailController, SettingsController }

function SettingsController(
	$scope,
	$confirm,
	$http,
	backendEntryPoint,
	loginService,
	$state,
	$stateParams,
	SettingsService,
	MessagesService,
	UserProfile,
	accessService
) {
	var vm = this

	if (accessService.hasRole('ROLE_USER')) {
		UserProfile.getSeatClass().then(function setSeatClass(response) {
			vm.seatClass = response

			if ($stateParams.info) {
				MessagesService.info($stateParams.info)
			}
			if ($stateParams.error) {
				MessagesService.error($stateParams.error)
			}
		})
		UserProfile.getSubscriptionCode().then(function setSubscriptionCode(
			response
		) {
			vm.subscriptionCode = response
		})
	}

	vm.submitChangeSeatClass = submitChangeSeatClass
	vm.submitChangeEmail = submitChangeEmail
	vm.submitChangePassword = submitChangePassword
	vm.closeAccount = closeAccount
	vm.submitChangeSubscriptionCode = submitChangeSubscriptionCode

	function submitChangeSubscriptionCode() {
		SettingsService.changeSubscriptionCode(
			$scope.currentUser().accountCode,
			vm.subscriptionCode
		)
			.then(successMessage)
			.catch(errorMessage)

		function successMessage() {
			MessagesService.success('Die ABO-Nummer wurde erfolgreich geändert.')
		}

		function errorMessage() {
			MessagesService.error(
				'Es ist ein Fehler aufgetreten. Die ABO-Nummer wurde nicht geändert.'
			)
		}
	}

	function closeAccount() {
		$confirm({
			text: 'Nach dem Löschen können Sie Ihr Konto nicht mehr verwenden. Bestehende Reservierungen werden storniert. Möchten Sie das Nutzerkonto wirklich löschen?',
			title: 'Löschen des Nutzerkontos bestätigen'
		}).then(function () {
			$http
				.post(backendEntryPoint + '/user/close-account', {
					username: $scope.currentUser().username
				})
				.then(function () {
					loginService.logout()
					$state.go('login.form')
				})
		})
	}

	function submitChangeEmail() {
		SettingsService.changeEmail($scope.currentUser().username, vm.newEmail)
			.then(successMessage)
			.catch(errorMessage)

		function successMessage() {
			MessagesService.success(
				'Zur Überprüfung wurde eine Email an die angegebene Adresse geschickt. Bitte prüfen Sie Ihren Posteingang. Verwenden Sie den in der Email enthaltenen Link, um die Änderung abzuschließen.'
			)
		}

		function errorMessage() {
			MessagesService.error(
				'Es ist ein Fehler aufgetreten. Die Email Adresse wurde nicht geändert.'
			)
		}
	}

	function submitChangePassword() {
		SettingsService.changePassword(vm.oldPassword, vm.newPassword)
			.then(successMessage)
			.catch(errorMessage)

		function successMessage() {
			MessagesService.success('Das Passwort wurde erfolgreich geändert.')
		}

		function errorMessage(error) {
			if (error.status === 403) {
				MessagesService.error(
					'Das aktuelle Passwort ist nicht korrekt. Ihr Passwort wurde nicht geändert.'
				)
			}
		}
	}

	function submitChangeSeatClass() {
		SettingsService.changeSeatClass($scope.currentUser().username, vm.seatClass)
			.then(successMessage)
			.catch(errorMessage)

		function successMessage() {
			MessagesService.success('Änderung erfolgreich gespeichert')
		}

		function errorMessage() {
			MessagesService.error('Fehler beim Speichern der Daten')
		}
	}
}

function ConfirmEmailController(
	$stateParams,
	$state,
	SettingsService,
	MessagesService,
	$filter
) {
	SettingsService.confirmChangeEmail($stateParams.token)
		.then(function () {
			$state.go('settings', {
				info: 'Änderung erfolgreich gespeichert. '
			})
		})
		.catch(function (error) {
			var message = angular.isDefined(error.data.key)
				? $filter('translate')(error.data.key)
				: 'Emailänderung war nicht erfolgreich'
			$state.go('settings', {
				error: message
			})
		})
}
