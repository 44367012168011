import angular from 'angular'

angular.module('resi.security').factory('accessService', ['tokenStorage', accessService])

function accessService(tokenStorage) {
	return {
		hasRole: hasRole
	}

	function hasRole(role) {
		var token = tokenStorage.retrieveUser()

		if (!token || !token.roles) {
			return false
		}

		for (var i = 0; i < token.roles.length; i++) {
			if (token.roles[i].toLowerCase() === role.toLowerCase()) {
				return true
			}
		}
		return false
	}
}
