;(function () {
	'use strict'

	angular
		.module('resi.booking.db-ticket')
		.filter('dbTicketTripDays', dbTicketTripDaysFilterFactory)

	// @ngInject
	function dbTicketTripDaysFilterFactory() {
		return dbTicketTripDaysFilter

		function dbTicketTripDaysFilter(days) {
			if (!angular.isString(days)) {
				return days
			}
			return '(' + days.replace(/ /g, '') + ')'
		}
	}
})()
