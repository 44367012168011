;(function () {
	'use strict'

	angular.module('resi.login').factory('loginService', ['$http', 'tokenStorage', 'backendEntryPoint', '$q', loginService])

	function loginService($http, tokenStorage, backendEntryPoint, $q) {
		// @ngInject
		return {
			login: login,
			loginByToken: loginByToken,
			isLoggedIn: isLoggedIn,
			currentUser: currentUser,
			updatePassword: updatePassword,
			logout: logout
		}

		function isLoggedIn() {
			return currentUser() !== null
		}

		function currentUser() {
			var token = tokenStorage.retrieveUser()
			return token
		}

		function storeToken(headers, user) {
			var roles = headers('X-Auth-Roles')
				? JSON.parse(headers('X-Auth-Roles'))
				: []
			var tenant = headers('X-Auth-Tenant')
			tokenStorage.storeUser({
				username: user.username,
				accountCode: user.accountCode,
				roles: roles,
				tenant: tenant
			})
		}

		function login(username, password) {
			return $q(function (resolve, reject) {
				$http
					.post(backendEntryPoint + '/login', {
						username: username,
						password: password
					})
					.then(function ({headers}) {
						$http
							.get(backendEntryPoint + '/users/' + username.toLowerCase())
							.then(function ({data}) {
                storeToken(headers, data)

								resolve(data)
							}, function(error){
                reject(error)
              })
					}, function (err) {
						tokenStorage.clear()
						reject(err)
					})
			})
		}

		function loginByToken(token) {
			return $http
				.post(backendEntryPoint + '/kin', {}, { params: { token: token } })
				.then(function (result, status, headers) {
					var username = angular.fromJson(
						atob(headers('X-Auth-Token').split('.')[1])
					).username
					storeToken(headers, { username: username })
				},function () {
					tokenStorage.clear()
				})
		}

		function updatePassword(username, oldPassword, newPassword) {
			return $http
				.post(backendEntryPoint + '/user/update-password', {
					username: username,
					newpassword: newPassword,
					oldpassword: oldPassword
				})
				.then(function (result, status, headers) {
					storeToken(headers, username)
				},function () {
					tokenStorage.clear()
				})
		}

		function logout() {
			tokenStorage.clear()
		}
	}
})()
