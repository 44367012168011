;(function () {
	angular
		.module('resi.booking')
		.factory('extendFormStateHandler', extendFormStateHandlerFactory)

	function extendFormStateHandlerFactory() {
		return {
			isInputDisabled: function (ctrlId) {
				return (
					ctrlId === 'fromOrt' ||
					ctrlId === 'toOrt' ||
					ctrlId === 'trip' ||
					ctrlId === 'returnTrip' ||
					ctrlId === 'returnTripFriday' ||
					ctrlId === 'hasReturnTripFriday'
				)
			}
		}
	}
})()
